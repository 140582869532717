import React from "react";
import { Sbody, Shead } from "../../style";
import Parallex from "./parallex";
const disease = [
  "Cancer",
  "Flu",
  "Covid",
  "Cancer",
  "Flu",
  "Covid",
  "COVID-19 health concerns, COVID-19 testing at home",
  "Flu",
  "Covid",
  "Cancer",
  "Flu",
  "Covid",
];
function Right({ data }) {
  return (
    <div
      style={{ height: "fit-content" }}
      className="shadow rounded-5 p-4 bg-white "
    >
      <div className="fw-bold h1 m-3">{data && data.content.header}</div>
      <div className="p-3">
        <Sbody
          dangerouslySetInnerHTML={{ __html: data && data.content.description }}
        ></Sbody>
      </div>
    </div>
  );
}
function Left({ data }) {
  return (
    <div className=" p-4">
      <div className="fs-3 lead ">{data && data.menu.header}</div>

      {data &&
        data.menu &&
        data.menu.menus.map((x) => {
          const y = x.contents[0];
          return (
            <div className="m-3">
              <Shead className="p-2">{y.header}</Shead>
              <Sbody
                className="p-2 "
                dangerouslySetInnerHTML={{ __html: y.description }}
              ></Sbody>
              <Sbody>
                {disease.map((x) => {
                  return (
                    <button className="p-1 d-inline px-4 btn rounded-pill  bg-white m-2 my-2 ms-0 hovergreen">
                      {x}
                    </button>
                  );
                })}
              </Sbody>
            </div>
          );
        })}
    </div>
  );
}
function Indvservices({ data, flip }) {
  return (
    <div>
      <Parallex
        flip={flip}
        left={<Left data={data} />}
        right={<Right data={data} />}
        bgcolor={"#96c7f844"}
      />
    </div>
  );
}

export default Indvservices;
