import { GlobalOutlined, LockOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import logo from "../mainlogo.png";
const regularExpression =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export default function Pwdresetaftermail() {
  const [u64, setu64] = useState();
  const [bid, setbid] = useState();
  const [mainpwd, setmainpwd] = useState("");
  const [main2pwd, setmain2pwd] = useState("");
  const searchParams = useLocation();
  const [firstrender, setfirstrender] = useState(true);
  const [empty, setempty] = useState();
  const [errorstatus, seterrorstatus] = useState();
  const [matched, setmatched] = useState();
  const [error, seterror] = useState({
    required: "Please enter Your password",
    error:
      "Passwords must have at least 8 characters, 1 lowercase, 1 uppercase, 1 number, and 1 special character.",
    match: "Passwords don't match",
  });
  const Navigate = useNavigate();
  useEffect(() => {
    const path = searchParams.pathname.split("/account/reset/")[1];

    setu64(path.split("/")[0]);
    setbid(path.split("/")[1]);
  }, []);
  function postdata() {
    const axios = require("axios");
    let data = JSON.stringify({
      password: `${mainpwd}`,
      repeat_password: `${main2pwd}`,
    });
    let config = {
      method: "post",
      url:
        process.env.REACT_APP_EXTERNAl_APPURL +
        "v1/accounts/password/set/" +
        searchParams.pathname.split("/account/reset/")[1],
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        message.success("Password Sucessfully Changed");
        Navigate("/");
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  }
  return (
    <div
      style={{
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center 110px",
        backgroundSize: "100%",
        backgroundImage: `url(${"https://gw.alipayobjects.com/zos/rmsportal/TVYTbAXWheQpRcWDaDMu.svg"})`,
      }}
      className="bgrey vh-100"
    >
      <div className="d-block text-end pt-4">
        <span className=" p-3 fs-6">
          <GlobalOutlined />
        </span>
      </div>
      <div
        style={{ marginTop: "70px", maxWidth: "450px" }}
        className="container-md p-0"
      >
        <div>
          {" "}
          <img className="d-block m-auto" width={200} src={logo} alt="" />
          <div className="tgrey p-2 fs-6 text-center">
            Continue to change password
          </div>
        </div>
        <div className="container-sm mt-4">
          <div className="bg-light border">
            <></>
          </div>
          <Input.Password
            className="m-auto tgrey "
            size="large"
            placeholder=" Password"
            prefix={<LockOutlined />}
            status={firstrender ? "" : !empty || !errorstatus ? "error" : ""}
            onChange={(e) => {
              {
                e.target.value === "" ? setempty(false) : setempty(true);
              }
              {
                e.target.value === main2pwd
                  ? setmatched(true)
                  : setmatched(false);
              }

              {
                regularExpression.test(e.target.value)
                  ? seterrorstatus(true)
                  : seterrorstatus(false);
              }
              setmainpwd(e.target.value);
            }}
          />
          <div className="text-danger p-2 m-0 pb-0 px-0">
            {" "}
            {empty || firstrender ? <></> : error.required}
          </div>
          <div className="text-danger p-2 m-0 pt-0 pb-1 px-0">
            {errorstatus || !empty || firstrender ? <></> : error.error}
          </div>
          <div></div>
          <Input.Password
            className="tgrey mt-1"
            size="large"
            placeholder="Confirm Password"
            prefix={<LockOutlined />}
            status={
              firstrender
                ? ""
                : !errorstatus || !empty || !matched
                ? "error"
                : ""
            }
            onChange={(e) => {
              {
                e.target.value === mainpwd
                  ? setmatched(true)
                  : setmatched(false);
              }
              setmain2pwd(e.target.value);
            }}
          />{" "}
          <div className="text-danger p-2 pb-0 px-0">
            {matched || !empty || firstrender ? <></> : error.match}
          </div>
          <div>
            {" "}
            <Button
              block
              style={{ height: "45px" }}
              className="mt-4"
              size="large"
              type="primary"
              onClick={() => {
                setfirstrender(false);
                {
                  regularExpression.test(mainpwd) & matched ? (
                    postdata()
                  ) : (
                    <></>
                  );
                }
              }}
            >
              Change Password
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
