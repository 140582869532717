import { Spin } from "antd";
import axios from "axios";
import moment from "moment";
import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { API, URLs } from "../../../config/urls";
import { Packages } from "../main";
import "./style/step2.module.css";

const Step2 = (props) => {
  const [step, setstep] = useState(0);
  const [processing, setprocessing] = useState(true);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setError,
  } = useForm();
  const [state, actions] = useContext(Packages);
  const Navigate = useNavigate();
  const onSubmit = async (data) => {
    async function aftercheck() {
      await actions({ ...state, ...data });
      window.scroll(0, 0);
      axios
        .post(URLs + "unfinished-members", {
          data: {
            email: data.Email,
            first_name: state.Firstname,
            middle_name: state.middle_name,
            last_name: state.Lastname,
            gender: state.gender,
            date_of_birth: moment(data.Dob).format("YYYY-MM-DD"),
            address1: state.Address,
            address2: state.Address_2,
            zip: state.Zip,
            city: state.City,
            state: state.State,
            language: state.language,
            phone: data.Mobileno,
            plan: state.plan,
            language: state.language,
          },
        })
        .then(function (response) {
          window.localStorage.setItem("del_keys", response.data.data.id);
          Navigate("/packages/results");
        })
        .catch(function (error) {
          Navigate("/packages/results");
        });
    }
    axios.post(API.checkemail, { email: data.Email }).then((x) => {
      x.data.exists
        ? setError("Email", {
            type: "exists",
          })
        : aftercheck();
    });
  };
  const onSubmitnext = (data) => {
    actions({ ...state, ...data });
    setstep(step + 1);
    window.scroll(0, 0);
  };
  useEffect(() => {
    state.price === undefined ? Navigate("/packages") : setprocessing(false);
  }, []);
  if (processing) return <Spin />;
  return (
    <div className="py-5">
      <div>
        <div className=" stepsbg row text-start fs-5 py-3 text-center m-0 ">
          <span className="col p-0 fs-5 ps-3 text-start p-e-1 ">
            <i className="bi bi-check-circle-fill"></i>
          </span>
          <span className="col-8 p-0 fs-6 text-center ">
            <span className="text-center fs-6">
              Package:{" "}
              <span className="fw-light fs-5">
                {state.title} $
                {state.terms === "yearly" ? (
                  <>{state.price.toFixed(2)}</>
                ) : (
                  <>{(state.price / 12).toFixed(2)}</>
                )}
                {state.terms === "monthly" ? "/MO" : "/YR"}
              </span>
            </span>
          </span>
          <span className="col-2 p-0 pe-3">
            <button
              style={{ fontSize: "10px", padding: "6px" }}
              onClick={() => Navigate("/packages/")}
              className="editplanbtn float-end border-white rounded-pill m-0"
            >
              Edit Plan
            </button>
          </span>
        </div>
        <div
          style={{ borderRadius: "0px" }}
          className=" stepsbg row fs-5 m-0 pb-2 pt-1 text-center"
        >
          <strong className="col pt-2 p-0 text-start ps-3">2</strong>
          <div className="col-8  ">
            <div className="border border-white mb-2 "></div>Costumer
            Information
          </div>
          <div className="col text-end"></div>
        </div>
      </div>
      <div style={{ backgroundColor: "#d2daff" }} className=" p-5">
        {step === 0 ? (
          <form className="" onSubmit={handleSubmit(onSubmitnext)}>
            <div
              style={{ maxWidth: 400 }}
              className="shadow-lg bg-white rounded-5 p-3 m-auto"
            >
              <div className="text-center lead">
                Lets Start With Some
                <div style={{ fontWeight: 500 }} className=" text-center lead">
                  Basic Information
                </div>
              </div>
              <div>
                <div className="row g-3 pt-0 p-3">
                  <div className="col-0">
                    <div className="row  ">
                      <label className="lead p-1 text-start" htmlFor="fname">
                        * First Name:
                      </label>
                      <input
                        className="checkoutinput"
                        type="text"
                        placeholder="First name"
                        id="fname"
                        {...register("Firstname", {
                          required: true,
                          maxLength: 80,
                        })}
                        defaultValue={state.Firstname}
                      />
                    </div>
                    <div className=" row text-danger  ">
                      {errors.Firstname?.type === "required" &&
                        "  Please enter First Name"}
                    </div>
                  </div>
                  <div className="col-0">
                    <div className="row  ">
                      <label className="lead p-1 text-start" htmlFor="mname ">
                        Middle Name:
                      </label>
                      <input
                        className="checkoutinput"
                        id="mname"
                        type="text"
                        placeholder="MIddle name"
                        {...register("middle_name", {
                          maxLength: 100,
                        })}
                        defaultValue={state.middle_name}
                      />
                    </div>
                  </div>
                  <div className="col-0">
                    <div className="row  ">
                      <label className="p-1 lead text-start" htmlFor="lname">
                        * Last Name:
                      </label>
                      <input
                        className="checkoutinput"
                        id="lname"
                        type="text"
                        placeholder="Last name"
                        {...register("Lastname", {
                          required: true,
                          maxLength: 100,
                        })}
                        defaultValue={state.Lastname}
                      />
                    </div>

                    <div className=" row text-danger  ">
                      {errors.Lastname?.type === "required" &&
                        "  Please enter Last Name"}
                    </div>
                  </div>
                </div>
                <input
                  className="mt-4 p-3 checkoutinput "
                  value={"next"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
        {step === 4 ? (
          <form className="" onSubmit={handleSubmit(onSubmitnext)}>
            <div
              style={{ maxWidth: 400 }}
              className="shadow-lg bg-white rounded-5 p-3 m-auto"
            >
              <div className="text-center lead">
                Lets Know More About You
                <div
                  style={{ fontWeight: 500, fontSize: 15 }}
                  className=" text-center lead"
                ></div>
              </div>
              <div className="row m-3">
                <div className="col">
                  <div className="row  ">
                    <label className="lead p-1 text-start" htmlFor="dob">
                      Date Of Birth:
                    </label>
                    <input
                      className="checkoutinput"
                      max={moment().format("YYYY-MM-DD")}
                      type="date"
                      placeholder="Date Of birth"
                      id="dob"
                      {...register("Dob", { required: true })}
                      defaultValue={state.Dob}
                    />
                  </div>

                  <div className=" row text-danger ">
                    {errors.Dob?.type === "required" &&
                      "  Please Enter Your Date Of Birth"}
                  </div>
                </div>
              </div>

              <div className="row mx-3 g-3">
                <div className="col-0">
                  <div className="row  ">
                    <label className="lead p-1 text-start" htmlFor="email">
                      Email:
                    </label>
                    <input
                      className="checkoutinput"
                      id="email"
                      type="text"
                      placeholder="Example@gmail.com"
                      {...register("Email", {
                        required: true,
                        pattern: /^\S+@\S+$/i,
                      })}
                      defaultValue={state.Email}
                    />
                  </div>
                  <div className=" row text-danger  ">
                    {errors.Email?.type === "required" && (
                      <p> Please enter your email</p>
                    )}
                    {errors.Email?.type === "pattern" && (
                      <p> Please enter a valid email</p>
                    )}
                    {errors.Email?.type === "exists" && (
                      <p> This email has already been used</p>
                    )}
                  </div>
                </div>
                <div className="col-0">
                  <div className="row  ">
                    <label className="lead p-1 text-start" htmlFor="phoneno">
                      Mobile Number:
                    </label>
                    <input
                      className="checkoutinput"
                      id="phoneno"
                      type="number"
                      placeholder="Mobile number"
                      {...register("Mobileno", {
                        required: true,
                        minLength: 6,
                        maxLength: 12,
                      })}
                      defaultValue={state.Mobileno}
                    />
                  </div>

                  <div className=" row text-danger  d-inline ">
                    {errors.Mobileno?.type === "required" &&
                      "  Please enter a valid Mobile Number "}
                    {errors.Mobileno?.type === "minLength" &&
                      "  Please enter a valid Mobile Number "}
                    {errors.Mobileno?.type === "maxLength" &&
                      "  Please enter a valid Mobile Number "}
                  </div>
                </div>
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
        {step === 3 ? (
          <form className="" onSubmit={handleSubmit(onSubmitnext)}>
            <div
              style={{ maxWidth: 400 }}
              className="shadow-lg bg-white rounded-5 p-3 m-auto"
            >
              <div className="text-center lead">
                Lets Know More About You
                <div
                  style={{ fontWeight: 500, fontSize: 15 }}
                  className=" text-center lead"
                >
                  Please Select Your Prefered Language
                </div>
              </div>
              <div>
                <input
                  className="d-none"
                  type="radio"
                  id="age1"
                  name="age"
                  value="English"
                  {...register("language", {
                    required: true,
                  })}
                />
                <label
                  style={{ border: "2px solid #283779", fontWeight: 500 }}
                  className="d-block p-3 m-4 rounded-5 lead "
                  htmlFor="age1"
                >
                  English
                </label>
                <input
                  className="d-none"
                  type="radio"
                  id="age2"
                  name="age"
                  value="Spanish"
                  {...register("language", {
                    required: true,
                  })}
                />
                <label
                  style={{ border: "2px solid #283779", fontWeight: 500 }}
                  className="d-block p-3 m-4 rounded-5 lead"
                  htmlFor="age2"
                >
                  Español
                </label>

                <input
                  className="mt-4 p-3 checkoutinput "
                  value={"next"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
        {step === 2 ? (
          <form className="" onSubmit={handleSubmit(onSubmitnext)}>
            <div
              style={{ maxWidth: 400 }}
              className="shadow-lg bg-white rounded-5 p-3 m-auto"
            >
              <div className="text-center lead">
                Lets Know More About You
                <div
                  style={{ fontWeight: 500, fontSize: 15 }}
                  className=" text-center lead"
                >
                  Please Select Your Gender
                </div>
              </div>
              <div>
                <input
                  className="d-none"
                  type="radio"
                  id="age1"
                  name="age"
                  value="Male"
                  {...register("gender", {
                    required: true,
                  })}
                />
                <label
                  style={{ border: "2px solid #283779", fontWeight: 500 }}
                  className="d-block p-3 m-4 rounded-5 lead "
                  htmlFor="age1"
                >
                  Male
                </label>
                <input
                  className="d-none"
                  type="radio"
                  id="age2"
                  name="age"
                  value="Female"
                  {...register("gender", {
                    required: true,
                  })}
                />
                <label
                  style={{ border: "2px solid #283779", fontWeight: 500 }}
                  className="d-block p-3 m-4 rounded-5 lead"
                  htmlFor="age2"
                >
                  Female
                </label>
                <input
                  className="d-none"
                  type="radio"
                  id="age3"
                  name="age"
                  value="Other"
                  {...register("gender", {
                    required: true,
                  })}
                />
                <label
                  style={{ border: "2px solid #283779", fontWeight: 500 }}
                  className="d-block p-3 m-4 rounded-5 lead "
                  htmlFor="age3"
                >
                  Other
                </label>
                <input
                  className="mt-4 p-3 checkoutinput "
                  value={"next"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}
        {step === 1 ? (
          <form className="" onSubmit={handleSubmit(onSubmitnext)}>
            <div
              style={{ maxWidth: 400 }}
              className="shadow-lg bg-white rounded-5 p-3 m-auto"
            >
              <div className="text-center lead">
                Please Enter Your Address
                <div
                  style={{ fontWeight: 500 }}
                  className=" text-center lead"
                ></div>
              </div>
              <div>
                <div className="row g-2 m-3">
                  <div className="col-0">
                    <div className="row  ">
                      <label className="lead p-1 text-start" htmlFor="addrs">
                        Address 1:
                      </label>
                      <input
                        className="checkoutinput"
                        type="text"
                        id="addrs"
                        placeholder="Address"
                        {...register("Address", {
                          required: true,
                          maxLength: 80,
                        })}
                        defaultValue={state.Address}
                      />
                    </div>
                    <div className=" row text-danger  ">
                      {errors.Address?.type === "required" &&
                        "  Please enter Address"}
                    </div>
                  </div>
                  <div className="col-0">
                    <div className="row  ">
                      <label className="lead p-1 text-start" htmlFor="addrs">
                        Address 2:
                      </label>
                      <input
                        className="checkoutinput"
                        type="text"
                        id="addrs"
                        placeholder="Address 2"
                        {...register("Address2", { maxLength: 80 })}
                        defaultValue={state.Address2}
                      />
                    </div>
                    <div className=" row text-danger  ">
                      {errors.Address2?.type === "required" &&
                        "  Please enter Address"}
                    </div>
                  </div>
                </div>
                <div className="row g-2 mx-3 ">
                  <div className="col-0">
                    <div className="row  ">
                      <label className="p-1 lead text-start" htmlFor="zip">
                        ZIP:
                      </label>
                      <input
                        className="checkoutinput"
                        id="zip"
                        type="text"
                        placeholder="Zip"
                        {...register("Zip", {
                          required: true,
                          minLength: 5,
                          maxLength: 5,
                        })}
                        defaultValue={state.Zip}
                      />
                    </div>
                    <div className=" row text-danger  ">
                      {errors.Zip?.type === "required" &&
                        "  Please enter a zip code"}
                      {errors.Zip?.type === "minLength" &&
                        "  Please enter a valid zip code"}
                      {errors.Zip?.type === "maxLength" &&
                        "  Please enter a valid zip code"}
                    </div>
                  </div>
                </div>
                <div className="row g-2 mx-3 my-1">
                  <div className="col-0 ">
                    <div className="row ">
                      <label className="lead p-1 text-start " htmlFor="city">
                        City:
                      </label>
                      <input
                        className="checkoutinput"
                        id="city"
                        type="text"
                        placeholder="City"
                        {...register("City", { required: true, maxLength: 80 })}
                        defaultValue={state.City}
                      />
                    </div>
                    <div className=" row text-danger  ">
                      {errors.City?.type === "required" &&
                        "  Please enter your City"}
                    </div>
                  </div>
                  <div className="col-0  ">
                    <div className="row  ">
                      <label className="lead p-1 text-start " htmlFor="state">
                        State:
                      </label>
                      <input
                        className="checkoutinput"
                        id="state"
                        type="text"
                        placeholder="State"
                        {...register("State", {
                          required: true,
                          maxLength: 100,
                        })}
                        defaultValue={state.State}
                      />
                    </div>

                    <div className=" row text-danger  d-inline ">
                      {errors.State?.type === "required" &&
                        "  Please enter your State "}
                    </div>
                  </div>
                </div>
                <input
                  className="mt-4 p-3 checkoutinput "
                  value={"next"}
                  type="submit"
                />
              </div>
            </div>
          </form>
        ) : (
          <></>
        )}

        {step === 4 ? (
          <form className="" onSubmit={handleSubmit(onSubmit)}>
            <input className="mt-4 p-3 checkoutinput " type="submit" />
          </form>
        ) : (
          <></>
        )}
        {step === 0 ? (
          <></>
        ) : (
          <input
            onClick={() => {
              setstep(step - 1);
              window.scroll(0, 0);
            }}
            className="mt-4 p-3 checkoutinput "
            value={"back"}
            type={"submit"}
          />
        )}
      </div>
      <div
        style={{ borderRadius: "0px 0px 29px 29px" }}
        className="stepsbg fs-4  p-4 text-center"
      >
        <strong className="float-start">3</strong>
        Review Information
      </div>
    </div>
  );
};

export default Step2;
