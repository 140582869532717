import React from "react";
import { imgconvert } from "../../helpers/imgconverter";
import image from "../img/cold.png";
function Whyloveuscompo({ data }) {
  return (
    <div>
      <h1>{data && data.name}</h1>
      <div className="row m-0 g-3">
        {data &&
          data.menus.map((x) => {
            return (
              <div className="col">
                <div className="m-auto" style={{ width: 150 }}>
                  <img
                    height={100}
                    className="rounded-circle"
                    src={imgconvert(x.contents[0].img[0].img.url)}
                    alt=""
                  />
                  <div>
                    <span className="fs-5 lead text-info fw-bold fw-bold">
                      {x.contents[0].header}
                    </span>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: x.contents[0].description,
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            );
          })}
      </div>
    </div>
  );
}

export default Whyloveuscompo;
