import "./nav.css";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

function Navb({ data, logo }) {
  const navigate = useNavigate();
  return (
    <div className="container-lg">
      <nav className="navbar  bg-md-light navbar-expand-md ">
        <div className="container-fluid p-0">
          <a href="/" className="navbar-brand p-2 ps-3 m-0">
            <img height={30} src={logo} alt="" />
          </a>
          <li className="nav-item justify-content-left  d-inline-block d-md-none me-2">
            <span
              className="navbar-toggler   p-2 border-0 text-dark"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="d-block d-sm-inline d-md-block p-1">
                <i className="bi bi-list "></i>
              </span>{" "}
              <span className="p">Menu</span>
            </span>
          </li>
          <div
            className="collapse navbar-collapse p-2 blurnav"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav m-auto me-3 mb-2 mb-lg-0  ">
              {data &&
                data.map((x, y) => {
                  if (x.__component === "index.menu") {
                    return (
                      <li key={x.id} className="nav-item dropdown  px-2">
                        <div className="dropdown">
                          <a className="nav-link">{x.name}</a>
                          <ul className="dropdown-menu rounded-5 shadow-lg p-3 px-2 text-start">
                            {x.menus.length === 1 ? (
                              <>
                                {x.menus[0].menus.map((d, e) => {
                                  return (
                                    <li key={d.id}>
                                      <a
                                        className="dropdown-item text-start"
                                        href={d.URL}
                                      >
                                        {d.Name}
                                      </a>
                                    </li>
                                  );
                                })}
                              </>
                            ) : (
                              <>
                                {x.menus.map((a) => {
                                  return (
                                    <div
                                      key={a.id}
                                      className="shadow-lg rounded-5 p-2 m-2"
                                    >
                                      <div className="fw-bold">{a.name}</div>
                                      {a.menus.map((c) => {
                                        return (
                                          <li key={c.id}>
                                            <a
                                              className="dropdown-item text-start"
                                              href={c.URL}
                                            >
                                              {c.Name}
                                            </a>
                                          </li>
                                        );
                                      })}
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </ul>
                        </div>
                      </li>
                    );
                  } else {
                    return (
                      <li key={x.id} className="nav-item  px-2">
                        <a className="nav-link" href={x.URL}>
                          {x.Name}
                        </a>
                      </li>
                    );
                  }
                })}
            </ul>
            <div className="px-2 d-none">
              {" "}
              <button className="revhomebtn h5  "> Sign Up</button>{" "}
            </div>
            <div className="px-2 border-0">
              {" "}
              <button
                onClick={() => {
                  navigate("/packages");
                }}
                className="homebtn border-0"
              >
                {" "}
                Sign Up
              </button>{" "}
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navb;
