import React from "react";
import { useContext } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { Packages } from "../../main";

export default function Payreview() {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [state, actions] = useContext(Packages);
  const Navigate = useNavigate();
  const onSubmit = (data) => {
    actions({ ...state, ...data });
  };
  return (
    <div className="col">
      <div className="row text-dark fs-6 px-5 py-3">
        <div>
          Welcome to our payment center. Make a one-time payment by clicking the{" "}
          <span className="fw-bold">PAY NOW </span>button.
        </div>
      </div>
      <div className="row"></div>
      <div className="row text-dark ">
        <div className="col">
          <div className="container w-50">
            <div className="Package shadow-lg " htmlFor={state.id}>
              <div
                className="w-100 p-2  text-white fw-bold"
                style={{
                  backgroundColor: "#283779",
                  borderRadius: "29px 29px 0px 0px",
                  letterSpacing: "2px",
                }}
              >
                {state.terms === "yearly" ? "Yearly" : "Monthly"} Plan
              </div>
              <div className="pt-2 ">{state.title}</div>

              <div className="mt-0 p-0" style={{ fontSize: "60px" }}>
                $
                {state.terms === "yearly" ? (
                  <>
                    {Number((state.price - (state.price % 1)).toFixed(0))}
                    <sup className="fs-1">
                      .
                      {Number((state.price % 1) * 100) === 0
                        ? "00"
                        : Number(((state.price % 1) * 100).toFixed(0))}
                    </sup>
                  </>
                ) : (
                  <>
                    {Number(
                      (state.price / 12 - ((state.price / 12) % 1)).toFixed(0)
                    )}
                    <sup className="fs-1">
                      .
                      {Number(((state.price / 12) % 1) * 100) === 0
                        ? "00"
                        : Number((((state.price / 12) % 1) * 100).toFixed(0))}
                    </sup>
                  </>
                )}
                <span className="fs-6">
                  {state.terms === "monthly" ? "/MO" : "/YR"}{" "}
                </span>
              </div>
            </div>
          </div>
          <form
            style={{ maxWidth: "none" }}
            className="w-100 pt-4"
            onSubmit={handleSubmit(onSubmit)}
          >
            <div className="container  text-wh w-50 shadow rounded-5 p-4 fs-6 text-center">
              <div className=" greentxt fw-bold">
                Automatically Bill My Account Next Time?
              </div>
              <input
                hidden
                {...register("Recurring", { required: true })}
                type="radio"
                value={true}
                id="RecurringY"
              />
              <label
                className="Package greenborder shadow p-2 m-2 "
                htmlFor="RecurringY"
              >
                Yes
              </label>
              <input
                hidden
                {...register("Recurring", { required: true })}
                type="radio"
                value={false}
                id="RecurringN"
              />
              <label
                className="Package greenborder shadow p-2 m-2 "
                htmlFor="RecurringN"
              >
                NO
              </label>
            </div>
            <div className="text-danger lead fs-6 text-center p-3">
              {errors.Recurring?.type === "required" &&
                "Please Choose Payment Type."}
            </div>
            <div className="pb-5  ">
              <button
                type="submit"
                className="btn editplanbtn rounded-pill w-75 m-auto d-block py-3 "
                onClick={() => {
                  state.page = "2";
                }}
              >
                Pay Now
              </button>
              <button
                onClick={() =>
                  actions({ ...state, ...{ paymentdisplay: true, page: "1" } })
                }
                className="btn editplanbtn rounded-pill w-25 m-auto d-block mt-2"
              >
                Cancel
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
