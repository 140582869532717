import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function Minicompare({ data }) {
  const [total, settotal] = useState(0);
  useEffect(() => {
    let total1 = 0;
    function find(data) {
      for (let i = 0; i < data.length; i++) {
        total1 = total1 + Number(data && data[i].number);
      }
      console.log(total1);
      settotal(total1);
    }
    data === undefined ? <></> : find(data.detail);
  });

  return (
    <div className="bg-white">
      {" "}
      <div className="container">
        <div className="row  p-3 py-5 g-3 m-0">
          <div className="col ">
            <div style={{ maxWidth: 450 }} className="mb-4 text-start">
              <span className="h1 bold  fw-bold ">{data && data.header}</span>{" "}
              <div
                className="lead m-4  text-start"
                dangerouslySetInnerHTML={{ __html: data && data.description }}
              ></div>
            </div>
          </div>
          <div className="col-md  ">
            <div className="row">
              {data &&
                data.detail.map((x, y) => {
                  return (
                    <div key={y}>
                      <div className="lead text-start">{x.description}</div>
                      <div className="py-3">
                        <div
                          style={{ backgroundColor: "#D9E7FF" }}
                          className="w-100 p-0"
                        >
                          <div
                            style={{
                              fontSize: 12,
                              width: `${
                                (1 - (total - x.number) / total) * 100
                              }%`,
                              backgroundColor: "#000f51",
                            }}
                            className="text-end px-1 m-0 p-0 fw-bold text-white"
                          >
                            ${x.number}
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Minicompare;
