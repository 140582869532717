import React from "react";
import { Link } from "react-router-dom";

function Details(props) {
  return (
    <div className="container shadow-lg rounded-5 p-5 my-5">
      <div className="row">
        <div className={`col ${props.image ? "" : "order-1"}`}>
          <span className="fw-bold fs-3 border-bottom border-3 ">
            {props.header}
          </span>

          <div
            style={{ fontWeight: 500 }}
            className="my-4 fs-4 text-start opacity-75"
          >
            {props.body1}
          </div>
          <div
            className="my-4 fs-5 text-start lead"
            dangerouslySetInnerHTML={{ __html: props.body2 }}
          ></div>
          <div className="row">
            <span className="col">
              <div className="fs-1 text-center">
                <Link to={"/packages"}>
                  {" "}
                  <button className="btn homebtn px-5 fs-6 rounded-5">
                    Book An Appointment
                  </button>
                </Link>
              </div>
            </span>
            <span className="col">
              <div className="fs-1 text-center">
                <Link to={"/packages"}>
                  {" "}
                  <button className="btn homebtn px-5 fs-6 rounded-5">
                    Learn More
                  </button>
                </Link>
              </div>
            </span>
          </div>
        </div>
        <div className="col m-auto">
          <img
            src={props.icon1}
            height={250}
            alt=""
            className="vertical-align-middle"
          />
        </div>
      </div>
    </div>
  );
}

export default Details;
