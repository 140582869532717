import { Spin } from "antd";
import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/urls";
import Getpricebyplan from "../../helpers/getpricebyplan";
import Slider_fixed from "./switch";

function Pricing_main() {
  const [terms, setterms] = useState("monthly");
  const navigate = useNavigate();
  const [data, setdata] = useState();
  const [packagedata, setpackage] = useState();
  const [processing, setprocessing] = useState(true);
  useEffect(() => {
    axios(API.getallprices).then((y) => {
      setpackage(y.data);
      axios(API.getpricing).then((x) => {
        setdata(x.data.data.pricing);
        setprocessing(false);
      });
    });
  }, []);

  function Table() {
    function Indv({ ind }) {
      return (
        <div className="col ">
          <div className="antdblue fw-bold fs-5 my-2">{ind.plan}</div>
          <div className="fw-bold antdblue fs-1">
            ${" "}
            {terms == "yearly"
              ? ind.price.toFixed(2)
              : Number.parseFloat(ind.price / 12).toFixed(2)}
            /
            <span className="fs-3 fw-bold pb-1">
              {terms == "monthly" ? "MO" : "YR"}
            </span>{" "}
          </div>
          <button
            onClick={() => {
              navigate("/packages");
            }}
            className="homebtn py-2 px-5 rounded-3 my-3"
          >
            Get Started
          </button>
        </div>
      );
    }
    function Indvrow({ ind }) {
      return (
        <div
          style={{ backgroundColor: "#D4EAFF" }}
          className="row py-4 my-4 rounded-4 shadow-sm"
        >
          <div className="col-4 fs-5 fw-bold text-start ps-5 antdblue">
            {ind.competitor_name}
          </div>
          <div className="col-sm">
            <div className="row">
              {packagedata.map((x) => {
                return (
                  <div className="col antdblue fw-bold">
                    {terms === "yearly"
                      ? Getpricebyplan(ind, x.plan) === null || 0 || undefined
                        ? "-"
                        : "$ " + Getpricebyplan(ind, x.plan).toFixed(2)
                      : Getpricebyplan(ind, x.plan) === null || 0 || undefined
                      ? "-"
                      : "$ " + (Getpricebyplan(ind, x.plan) / 12).toFixed(2)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      );
    }
    return (
      <>
        {" "}
        <Slider_fixed terms={[terms, setterms]} />
        <div className="row shadow-lg rounded-5 p-4 mt-5">
          <div className="col-4">
            <div className="text-start  antdblue fw-bold fs-3 p-3 ">
              Our Pricing compared to our compitetors
            </div>
          </div>
          <div className="col-sm">
            <div className="row">
              {packagedata.map((x) => {
                return <Indv ind={x} key={x.id} />;
              })}
            </div>
          </div>{" "}
        </div>
        <div>
          {data &&
            data.map((x) => {
              return (
                <div key={x.id}>
                  <Indvrow ind={x} />
                </div>
              );
            })}
        </div>
      </>
    );
  }
  return (
    <div style={{ paddingTop: 150 }} className="container">
      <div className="text-start antdblue fw-bold fs-6 ">Pricing</div>
      <div
        onClick={() => {
          console.log(terms);
        }}
        className=" antdblue fw-bold fs-2 text-start py-3"
      >
        Simple, Transparent pricing
      </div>
      <div>
        <div className="py-5">{processing ? <Spin /> : <Table />}</div>
      </div>
    </div>
  );
}

export default Pricing_main;
