import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { API } from "../../../config/urls";
import { convertlr } from "../../../helpers/covertlr.ts";
import { imgconvert } from "../../../helpers/imgconverter";
import Comparemain from "../../Local/components/compare";

function Primarycaremain() {
  const [data, setdata] = useState();
  useEffect(() => {
    axios(API.getvirtual).then((x) => {
      console.log(x.data.data);
      setdata(x.data.data);
    });
  }, []);
  return (
    <div>
      {" "}
      <div className="position-relative bghex" style={{}}>
        <div className="container-lg p-0 py-5">
          <div style={{ paddingTop: 80 }} className="row">
            <div className="lead">{data && data.header}</div>
            <div style={{ fontSize: 60 }} className="h1">
              {data && data.sub_header}
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 container">
        <div className="h1 fw-light">
          {data && data.content && data.content.name}
        </div>
        <div style={{ maxWidth: 500 }} className="m-auto my-4">
          {data && data.content && data.content.description}
        </div>
        <div className="fw-bold fs-5">
          {" "}
          {data && data.content && data.content.sub_heading}
        </div>
        <div>
          {" "}
          <button className="btn homebtn m-2">Learn More</button>
        </div>
      </div>
      <Comparemain data={data && data.compare} />
      <div
        style={{ backgroundColor: "#1233" }}
        className="my-5 py-4 rounded-5 container bg1"
      >
        <div className="row p-3">
          <div
            className={`col ${
              convertlr(data && data.content && data.content.img[0].pos)
                ? "order-1"
                : ""
            }`}
          >
            <img
              className="rounded-4"
              height={500}
              src={imgconvert(
                data && data.content && data.content.img[0].img.url
              )}
              alt=""
            />
            <div style={{ maxWidth: 300 }} className="p-3 m-auto fw-bold">
              {data && data.details.detail}
            </div>
          </div>
          <div className="col">
            <div>
              <h1 className="text-">
                {data && data.content && data.content.name}
              </h1>
              <div className=" fs-5 fw-light">
                {data && data.content && data.content.description}
              </div>

              <div>
                {" "}
                <button className="btn homebtn m-2">Learn More</button>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ backgroundColor: "#1233" }} className="py-5 ">
        <div
          className="fs-2 fw-bold m-auto lead text-white "
          style={{ maxWidth: 500 }}
        >
          {data && data.banner && data.banner.name}
        </div>
        <div style={{ color: "#1233", fontSize: 12 }} className="">
          {data && data.banner && data.banner.description}
        </div>
      </div>
      <div style={{ backgroundColor: "#ffefd633" }} className=" py-5">
        <div className="container">
          {" "}
          <div className="row">
            <div className="col text-center h1 fs-1">
              {data && data.last_part && data.last_part.content.name}
              <div className="">
                <div
                  style={{ maxWidth: 500 }}
                  className="m-auto bg-light shadow-lg p-4 rounded-5 my-4"
                >
                  <div style={{ lineHeight: 2 }} className="lead">
                    {data &&
                      data.last_part &&
                      data.last_part.content.description}
                  </div>
                </div>
                <div>
                  <button className="btn homebtn">Learn More</button>
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="">
                <div className="m-auto" style={{ maxWidth: 250 }}>
                  <div className="lead border-3 border-bottom">
                    BY THE NUMBERS
                  </div>
                  {data &&
                    data.last_part &&
                    data.last_part.percent_list.map((x) => {
                      return (
                        <div
                          key={x.id}
                          className="shadow p-4 rounded-5 m-3 bg-light"
                        >
                          <div className="text-info h1 fw-bold m-2">
                            {x.number}%
                          </div>
                          <div>{x.description}</div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Primarycaremain;
