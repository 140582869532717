import React, { Component } from "react";
import Indpackage from "./components/indpackage";
import Sidenav from "./components/sidenav";

export class Packages extends Component {
  render() {
    const { data } = this.props;
    return (
      <>
        <div className="container pt-5 ">
          <h1 className="fw-bolder fs-1"> {data && data.content.header}</h1>
          <div
            className="container-sm py-2 lead pcenter"
            dangerouslySetInnerHTML={{
              __html: data && data.content.description,
            }}
          ></div>
        </div>
        <div className="container-xl py-5 bg1 rounded-5 px-5 ">
          <div className="row g-5">
            <div className="col-lg">
              <div className="row g-5">
                {data &&
                  data.menu.menus.map((x) => {
                    return (
                      <div  className="col-sm  ">
                        <Indpackage data={x} />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Packages;
