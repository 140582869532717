import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../config/urls";
import { imgconvert } from "../../helpers/imgconverter";
import { Shead, Sbody } from "../style";
const color = [
  "#f7e9b4",
  "#b4f0f7",
  "#f1a1a1",
  "#E5D2C4",
  "#C4D7E5 ",
  "#C4C7E5",
  "#C4E5E3",
  "#E3C4E5",
  "#B7A89D",
  "#CBDDE3 ",
  "#FFCFBB",
];

function Indv(props) {
  const str = props.body;
  let count = 0;
  let there = 0;
  for (var i = 0; i < str.length; i++) {
    if (count === 2) {
      there = i;
      return (
        <div style={{ height: "inherit" }} className="col-md">
          <div style={{ width: 400, margin: "auto" }}>
            <div
              style={{ backgroundColor: `${props.bg}` }}
              className="shadow-lg rounded-5 p-4 "
            >
              <div className="fw-bold lead text-start">{props.head}</div>
              <div
                style={{ lineHeight: 2, fontSize: "12px" }}
                className="text-start my-3"
                dangerouslySetInnerHTML={{ __html: props.body.slice(0, there) }}
              ></div>
              <div>
                <button
                  onClick={() => {
                    window.scroll(0, 1500 + props.scroll);
                  }}
                  className="bg-white rounded-3 btn shadow p-2 mt-2 w-100 fw-bold px-3"
                >
                  Learn More
                </button>
              </div>
            </div>
          </div>
        </div>
      );
    }

    str[i] === "." ? count++ : <></>;
  }
}
function Indv1(props) {
  return (
    <div className="row bg-white rounded-5 py-3 my-5  g-5 ">
      <div
        style={{ alignSelf: "center" }}
        className={`col-md p-4 ${props.order === "1" ? "order-2" : ""}`}
      >
        <div>
          <img
            src={props.img}
            className="rounded-5 shadow-lg d-block m-auto"
            style={{ maxWidth: "40%", objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>
      <div className="col p-4">
        <Shead className="pb-3">{props.head}</Shead>
        <Sbody dangerouslySetInnerHTML={{ __html: props.body }}>{}</Sbody>
        <button
          onClick={() => {
            window.location.replace(props.link);
          }}
          className="homebtn btn mt-3"
        >
          Learn More
        </button>
      </div>
    </div>
  );
}
function Whowetreat() {
  const [data, setData] = useState();
  useEffect(() => {
    axios(API.getwho_we_serve).then((x) => {
      setData(x.data.data);
    });
  }, []);
  return (
    <div>
      <div className="position-relative bghex" style={{}}>
        <div className="container-md px-1 p-0 py-5">
          <div style={{ paddingTop: 80 }} className="">
            <div className="m-auto p-4" style={{}}>
              <h1>{data && data.content.header}</h1>
              <div
                style={{ lineHeight: 2 }}
                className="lead m-3 lineheight-2"
                dangerouslySetInnerHTML={{
                  __html: data && data.content.description,
                }}
              ></div>
              <div>
                <button className="btn homebtn fw-bold m-4 ">
                  Request A Visit
                </button>
              </div>
              <div>
                <button
                  onClick={() => {
                    window.scroll(0, 400);
                  }}
                  className="btn p-2 fs-3 fw-bolder"
                >
                  Learn more what can treat.
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row g-4 m-3">
          {data &&
            data.menu &&
            data.menu &&
            data.menu.menus.map((x, y) => {
              return (
                <Indv
                  key={x.id}
                  scroll={y * 750}
                  bg={color[y]}
                  head={x.contents[0].header}
                  body={x.contents[0].description}
                />
              );
            })}
        </div>
        <div className="py-5">
          {data &&
            data.menu &&
            data.menu &&
            data.menu.menus.map((x, y) => {
              return (
                <Indv1
                  key={x.id}
                  order={y % 2 === 0 ? "1" : "2"}
                  link={"/talktherapy"}
                  img={imgconvert(x.contents[0].img[0].img.url)}
                  head={x.contents[0].header}
                  body={x.contents[0].description}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Whowetreat;
