import axios from "axios";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { API } from "../../config/urls";
import { convertlr } from "../../helpers/covertlr.ts";
import { imgconvert } from "../../helpers/imgconverter";
function AboutMain() {
  const [data, setdata] = useState();
  const [how, sethow] = useState();
  useEffect(() => {
    axios(API.getabout).then((response) => {
      setdata(response.data.data);
      console.log(response.data.data);
    });
    axios(API.gethowitworks).then((x) => {
      sethow(x.data.data.contents);
    });
  }, []);
  return (
    <div>
      <div className="position-relative bghex ">
        <div style={{ paddingTop: "100px" }}>
          <div className="container-md py-5">
            <div className="row">
              <div className="col-sm-0 col">
                <img
                  className="m-auto d-block"
                  src={imgconvert(data && data.Image.url)}
                  height={500}
                  alt=""
                />
              </div>{" "}
              <div className={`col-sm-5 m-auto`}>
                <div className="m-auto">
                  <div
                    style={{ letterSpacing: "0.3rem" }}
                    className="h5 opacity-75 text-start fw-light mt-5"
                  >
                    ABOUT US
                  </div>
                  <div
                    style={{
                      color: "#283779",
                      lineHeight: "3rem",
                      fontWeight: "600",
                    }}
                    className="h1  opacity-75 text-start my-5 mt-0"
                  ></div>
                  <div
                    style={{ lineHeight: "2.5rem" }}
                    className="h6 opacity-75 text-start"
                    dangerouslySetInnerHTML={{
                      __html: data && data.description,
                    }}
                  ></div>
                  <div className="my-5 text-start">
                    <button className="btn homebtn ms-0 m-0 px-5 p-3 fs-5 rounded-5">
                      {data && data.button}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="postition-relative bghex">
        <div className="container py-5">
          <div className="row">
            <div
              style={{ alignSelf: "center" }}
              className={`col-md  ${
                convertlr(data && data.info.contents[0].img[0].pos)
                  ? "order-1"
                  : ""
              }`}
            >
              <img
                className="m-auto"
                height={500}
                src={imgconvert(data && data.info.contents[0].img[0].img.url)}
                alt="pic"
              />
            </div>
            <div className="col">
              <div
                style={{
                  color: "#283779",
                  lineHeight: "3rem",
                  fontWeight: "600",
                }}
                className="h2 text-center my-5"
              >
                {data && data.info.contents[0].header}
              </div>
              <div
                style={{
                  lineHeight: "2rem",
                  fontWeight: "300",
                }}
                className="my-5 fs-6 text-center"
                dangerouslySetInnerHTML={{
                  __html: data && data.info.contents[0].description,
                }}
              ></div>
            </div>
          </div>
          <div className="container py-5 border-top border-bottom border-white border-2 ">
            <div className="row py-4">
              {data &&
                data.brief &&
                data.brief.map((x) => {
                  return (
                    <div className="col">
                      <div>
                        <div
                          style={{
                            color: "#283779",
                            lineHeight: "3rem",
                            fontWeight: "600",
                          }}
                          className="text-center fs-1"
                        >
                          {x.number}+
                        </div>
                        <div className=""> {x.description}</div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
          <div className="container rounded-5 blurnav shadow-lg row p-3 m-auto">
            <div className="col-sm-9">
              <div
                style={{
                  color: "#283779",
                  lineHeight: "3rem",
                  fontWeight: "600",
                }}
                className="text-start fs-5"
              >
                {data && data.banner && data.banner.header}
              </div>
              <div
                className="fw-light text-start"
                dangerouslySetInnerHTML={{
                  __html: data && data.banner && data.banner.description,
                }}
              ></div>
            </div>
            <div className="col">
              <button className="btn homebtn ms-0 m-0 px-5 p-3 m-2 fs-5 rounded-5">
                Call us Now
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="postition-relative bghex py-5">
        <div
          style={{
            color: "#283779",
            lineHeight: "3rem",
            fontWeight: "600",
          }}
          className="h1 fw-bold p-4"
        >
          How it works.
        </div>
        <div className=" container">
          {" "}
          <div className="row g-5">
            <div
              className={`col-md ${
                convertlr(data && data.howitworks && data.howitworks.pos)
                  ? ""
                  : "order-1"
              }`}
            >
              <div className="container blurnav shadow-lg  rounded-5">
                {how &&
                  how.map((x, y) => {
                    return (
                      <div className="text-start row">
                        <span
                          style={{
                            fontSize: "72px",
                            verticalAlign: "middle",
                            width: 10,
                          }}
                          className="col-1 fw-bolder text-start mx-3 text-light"
                        >
                          {y + 1}
                        </span>
                        <div className="col text-start p-4 mx-3">
                          {" "}
                          <div
                            style={{
                              color: "#283779",

                              fontWeight: "600",
                            }}
                            className="fs-5 text-start   "
                          >
                            {x.header}
                          </div>
                          <div
                            className="text-start "
                            dangerouslySetInnerHTML={{ __html: x.description }}
                          ></div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </div>
            <div className="col-md-4">
              <img
                height={500}
                src={imgconvert(
                  data && data.howitworks && data.howitworks.img.url
                )}
                alt=""
                className="m-auto"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="postition-relative bghex py-5 ">
        <div
          style={{
            color: "#283779",
            lineHeight: "3rem",
            fontWeight: "600",
          }}
          className="h1 fw-bold"
        >
          Explore More
        </div>
        <div className="container my-5">
          <div className="row g-4 ">
            {data &&
              data.explore_more.menus.map((x) => {
                return (
                  <NavLink to={x.URL} key={x.id} className="col">
                    <div
                      style={{ width: 200 }}
                      className="rounded-5 blurnav shadow-lg p-4 m-auto "
                    >
                      <div
                        style={{
                          color: "#283779",
                          lineHeight: "3rem",
                          fontWeight: "600",
                        }}
                        className="fs-4 fw-bold"
                      >
                        {x.Name}
                      </div>
                    </div>
                  </NavLink>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
}

export default AboutMain;
