import { Button, Modal } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Changepwd from "./changepwd";

export default function Advanced({ spanish }) {
  const Navigate = useNavigate();
  const [modal, setmodal] = useState(false);
  return (
    <>
      {modal ? (
        <Modal
          title=""
          footer={[
            <Button key="back" onClick={() => setmodal(false)}>
              {spanish ? "Cancelar" : " Cancel"}
            </Button>,
          ]}
          onCancel={() => setmodal(false)}
          visible={true}
        >
          <Changepwd spanish={spanish} />
        </Modal>
      ) : (
        <></>
      )}

      <div className="p-4">
        <h2 className="fs-5">
          {spanish ? "Configuración de seguridad" : "Security Settings"}
        </h2>
        <div className="row py-4">
          <div className="col-5">
            <div style={{ fontWeight: "500" }}>
              {spanish ? "Contraseña de la cuenta" : "Account Password"}
            </div>
            <div className="tgrey">
              <span>
                {" "}
                {spanish
                  ? "Fuerza de la contraseña actual:"
                  : "Current password strength："}
                <span style={{ color: "#44f161" }}>
                  {" "}
                  {spanish ? "Fuerte" : "Strong"}
                </span>
              </span>
            </div>
          </div>
          <div className="col"></div>
          <div
            onClick={() => {
              setmodal(true);
            }}
            className="col-4 text-end m-auto text-primary"
          >
            <Button type={"primary"}>{spanish ? "Modificar" : "Modify"}</Button>
          </div>
        </div>
      </div>
    </>
  );
}
