import { createStore, StateMachineProvider } from "little-state-machine";
import React, { createContext, useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import Result from "./steps/Result";
import Step1 from "./steps/Step1";
import Step2 from "./steps/Step2";
import "./steps/style/styles.css";
createStore({});
export const Packages = createContext([
  {
    package_tiers: [{}],
  },
]);

function Check() {
  const [contextstate, setcontextstate] = useState({
    title: "",
    paymentdisplay: true,
    page: "1",
    terms: "yearly",
    del_keys: null,
  });

  return (
    <div className="bg1 p-2 py-5">
      <StateMachineProvider>
        <Packages.Provider value={[contextstate, setcontextstate]}>
          <div style={{ maxWidth: 1000 }} className="pb-5 container-md">
            <Routes>
              <Route path="/" element={<Step1 />} />
              <Route path="step2" element={<Step2 />} />
              <Route path="results" element={<Result />} />
            </Routes>
          </div>
        </Packages.Provider>
      </StateMachineProvider>
    </div>
  );
}

export default Check;
