import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { URL } from "../../../../config/urls";
import Packagesdata from "../../../../data";
import { convertlr } from "../../../../helpers/covertlr.ts";
import { imgconvert } from "../../../../helpers/imgconverter";
import { Downscrollbutton } from "../../../style";
function Hometop({ data, diseases }) {
  const navigate = useNavigate();
  const [showmenu, setshowmenu] = useState(
    diseases &&
      diseases[0] &&
      diseases[0].menus &&
      diseases[0].menus[0] &&
      diseases[0].menus[0].diseases
  );
  const [selected, setselected] = useState(0);
  useEffect(() => {
    setshowmenu(
      diseases &&
        diseases[0] &&
        diseases[0].menus &&
        diseases[0].menus[0] &&
        diseases[0].menus[0].diseases
    );
  }, [diseases]);

  const selectedcss =
    "p-1 px-2 mx-2 my-1 border-0 btn fw-bold rounded-4 bg-light py-1";

  function Diseasebox({ data }) {
    const [on, seton] = useState(false);
    return (
      <div
        style={{ transition: " all .3s ease-in-out" }}
        onMouseEnter={() => {
          seton(true);
        }}
        onMouseLeave={() => {
          seton(false);
        }}
        className="col-sm m-2 p-0 rounded-4 bg-white shadow p-0 "
      >
        {on ? (
          <div style={{ height: 136, paddingTop: 60 }}>
            <button
              onClick={() => {
                navigate(
                  diseases &&
                    diseases[0] &&
                    diseases[0].menus &&
                    diseases[0].menus[0] &&
                    diseases[0].menus[0].url
                );
              }}
              className="btn homebtn m-auto p-auto vertical-align-middle d-block"
            >
              Learn More
            </button>
          </div>
        ) : (
          <div
            onClick={() => {
              navigate(
                imgconvert(
                  diseases &&
                    diseases[0] &&
                    diseases[0].menus &&
                    diseases[0].menus[0] &&
                    diseases[0].menus[0].url
                )
              );
            }}
          >
            <img
              style={{
                borderTopRightRadius: 13,
                borderTopLeftRadius: 13,
                borderBottomRadius: 0,
                objectFit: "cover",
              }}
              width={"100%"}
              height={120}
              src={imgconvert(data.img.url)}
              alt=""
            />
            <div style={{ fontSize: 14, fontWeight: 500 }} className=" py-2 ">
              <span className="float-start ps-3 p-2">{data.name}</span>
              <span>
                <i className="bi bi-arrow-right float-end pe-3 p-2"></i>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {" "}
      <div className="position-relative bg1 pb-5" style={{}}>
        <div className="container-lg p-0 ">
          <div style={{ paddingTop: 100 }} className="row m-0">
            <div
              className={`col-md-5 head123 ${
                convertlr(data && data.content.img[0].pos) ? "order-1" : ""
              }`}
            >
              <img
                height={500}
                className="m-auto  d-none d-md-block"
                src={`${URL}${data && data.content.img[0].img.url}`}
                alt=""
              />
            </div>
            <div className="col ">
              <div className="container">
                <div
                  style={{ fontSize: 50 }}
                  className="h1 fw-bold text-start head123  "
                  dangerouslySetInnerHTML={{
                    __html: data && data.content.header,
                  }}
                ></div>
                <div
                  className="label text-start pt-3 w-75 body123"
                  dangerouslySetInnerHTML={{
                    __html: data && data.content.description,
                  }}
                ></div>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    navigate("/packages");
                  }}
                  className="homebtn shadow-lg fs-3 fw-bold w-50 my-4"
                >
                  {data && data.button}
                </div>
                <div className="fw-bold text-start w-100">
                  {diseases &&
                    diseases.map((x, y) => {
                      const link = x.menus[0].url;
                      return (
                        <button
                          key={y}
                          onClick={() => {
                            setselected(y);
                            setshowmenu(x.menus[0].diseases);
                          }}
                          className={
                            selected === y
                              ? selectedcss
                              : "p-1 px-2 mx-2 my-1 rounded-pill btn"
                          }
                        >
                          {x.header}
                        </button>
                      );
                    })}
                </div>

                <div className="row pt-4 pe-3">
                  {showmenu &&
                    showmenu.map((x, y) => {
                      return <Diseasebox data={x} />;
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <Downscrollbutton className="rounded-circle d-none">
          <i className="bi bi-chevron-down fs-1 "></i>
        </Downscrollbutton>
      </div>
    </div>
  );
}

export default Hometop;
