import axios from "axios";
import React, { useEffect, useState } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import App from "../App";
import { API } from "../config/urls";
import AboutMain from "./about/main";
import Accontcreatedmain from "./account-created";
import { default as Allbundles, default as Indvbundle } from "./bundlespage";
import Checkoutmain from "./checkout/main";
import ContactMain from "./contact/main";
import Faqmain from "./faqs/main";
import Productsind from "./Home/Components/products/productsind";
import Productsind1 from "./Home/Components/products/productsind1";
import Home from "./Home/index";
import Howitworksmain from "./howitworks/main";
import Offisignup from "./officeform";
import Ourprovidersmain from "./ourproviders/main";
import Qquery from "./quickquery/Qquery";
import ServicesMain from "./services/main";
import Medicalcaremain from "./whatwedo/medicalcare/main";
import Mentalcaremain from "./whatwedo/mentalcare/main";
import Primarsycaremain from "./whatwedo/primarycare/main";
import Whatwetreat from "./whatwetreat/main";
import Whowetreat from "./whowetreat/main";
import Whyusmain from "./whyus/Whyusmain";
import { Navigate } from "react-router-dom";
import useToken from "./member-dashboard/useToken";
import MembDashboard from "./member-dashboard/memberdashboard/main";
import PwdReset from "./member-dashboard/pwdreset/main";
import Pwdresetaftermail from "./member-dashboard/pwdresetaftermail/main";
import Dashlogin from "./member-dashboard/dashlogin/dashlogin";
import Pricing_main from "./pricing";

export default function Webroutes() {
  const [packages, setpackages] = useState([]);
  const { token, setToken } = useToken();
  const [tokenval, settokenval] = useState(token);
  useEffect(() => {
    axios(API.getpackages).then(function response(response) {
      setpackages(response.data.data);
    });
  }, []);
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<App />}>
          <Route exact path="/" element={<Home />}>
            <Route path="/1" element={<Productsind />} />
            <Route path="2" element={<Productsind1 />} />
          </Route>
          <Route exact path="/packages/*" element={<Checkoutmain />} />
          <Route exact path="/pricing" element={<Pricing_main />} />
          <Route exact path="/qq" element={<Qquery />} />
          <Route exact path="/indv" element={<Allbundles />} />
          <Route exact path="/about" element={<AboutMain />} />
          <Route exact path="/faqs" element={<Faqmain />} />
          <Route exact path="/services" element={<ServicesMain />} />
          <Route exact path="/whyus" element={<Whyusmain />} />
          <Route exact path="/howitworks" element={<Howitworksmain />} />
          <Route exact path="/contact" element={<ContactMain />} />
          <Route exact path="/ourproviders" element={<Ourprovidersmain />} />
          <Route
            exact
            path="/virtualprimarycare"
            element={<Primarsycaremain />}
          />
          <Route exact path="/mentalcare" element={<Mentalcaremain />} />
          <Route exact path="/medicalcare" element={<Medicalcaremain />} />
          <Route exact path="/what-we-treat" element={<Whatwetreat />} />
          <Route exact path="/who-we-serve" element={<Whowetreat />} />
          <Route exact path="/office-signup" element={<Offisignup />} />
          <Route
            exact
            path="/account-created"
            element={<Accontcreatedmain />}
          />
          {packages.map((x, y) => {
            return (
              <Route
                key={x.id}
                path={x.packagedetails.url}
                element={<Indvbundle data={x} key={y} />}
              />
            );
          })}
        </Route>
        <Route
          path="/dashboard/*"
          element={
            tokenval === null ? <Navigate to={"/login"} /> : <MembDashboard />
          }
        />
        <Route path="/resetpassword" element={<PwdReset />} />
        <Route
          path="/login"
          element={
            tokenval === null ? <Dashlogin /> : <Navigate to={"/dashboard"} />
          }
        />

        <Route path="/account/reset/*" element={<Pwdresetaftermail />} />
      </Routes>
    </BrowserRouter>
  );
}
