import {
  CreditCardOutlined,
  DashboardOutlined,
  LogoutOutlined,
  MedicineBoxOutlined,
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  SettingOutlined,
  UsergroupAddOutlined,
  UserOutlined,
} from "@ant-design/icons";
import { Dropdown, Layout, Menu, message, Space, Spin } from "antd";
import React, { useEffect, useState } from "react";
import { NavLink, Route, Routes } from "react-router-dom";

import useId from "../useId";
import useToken from "../useToken";
import Dashboardpage from "./memberdash/dashboard/main";
import MemberProfile from "./memberdash/profile/main";
import Subbedpackages from "./memberdash/subbedpackages/main";

import mainlogo from ".././mainlogo.png";
import MemberSettingsmain from "./memberdash/Settings/main";
const { Header, Content, Footer, Sider } = Layout;

function MembDashboard() {
  const { Id, setId } = useId();
  const [collapsed, setCollapsed] = useState(false);
  const [isloading, setisloading] = useState(false);
  const [username, setusername] = useState();
  const [sidewidth, setsidewidth] = useState("256px");
  const [selectedkey, setselectedkey] = useState("d1");
  const { token, setToken } = useToken();
  const [effect, seteffect] = useState();
  const [userid, setuserid] = useState(sessionStorage.getItem("accountid"));
  const [show, setshow] = useState(false);
  const [spanish, setspanish] = useState(
    window.localStorage.getItem("language") === "Spanish" ? true : false
  );
  const storage = window.localStorage.getItem("language");
  const userdetails = () => {
    const axios = require("axios");
    let config = {
      method: "get",
      url: process.env.REACT_APP_EXTERNAl_APPURL + "v1/users/me/",
      headers: {
        Accept: "application/json",
        Authorization: "Token" + " " + `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        getmembdetails(response.data.id);
        sessionStorage.setItem("accountemail", response.data.email);
        sessionStorage.setItem("accountid", response.data.id);
        sessionStorage.setItem("accountusername", response.data.first_name);
        sessionStorage.setItem("accountmode", response.data.mode);
        setusername(response.data.first_name);
        setuserid(response.data.id);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const getmembdetails = (membid) => {
    const axios = require("axios");

    let config = {
      method: "get",
      url:
        process.env.REACT_APP_EXTERNAl_APPURL +
        "v1/normal/membership-detail/" +
        membid +
        "/",
      headers: {
        Accept: "application/json",
        Authorization: "Token" + " " + `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setId(response.data);
        setshow(true);
      })
      .catch((error) => {
        message.error("Unable to load Membership Detals.");
      });
  };
  useEffect(() => {
    userdetails();
  }, [effect]);

  const menu = (
    <Menu
      items={[
        {
          key: "1",
          label: (
            <NavLink to="/dashboard/settings/basic">
              <span className="align-middle">
                {spanish ? "Ajustes" : "Account Settings"}
              </span>
            </NavLink>
          ),
          icon: <SettingOutlined />,
        },
        {
          key: "2",
          label: (
            <a
              href="/"
              onClick={() => {
                sessionStorage.clear();
                localStorage.clear();
              }}
            >
              <span className="align-middle">
                {spanish ? "Cerrar Sesión" : "Logout"}
              </span>
            </a>
          ),
          icon: <LogoutOutlined />,
        },
      ]}
    />
  );

  return (
    <div style={{ overflowX: "hidden", backgroundColor: "#ddeefc" }}>
      <Layout
        style={{
          minHeight: "100vh",
        }}
      >
        <Sider
          trigger={null}
          width={sidewidth}
          breakpoint="lg"
          onBreakpoint={(broken) => {
            {
              broken ? setsidewidth(0) : setsidewidth(256);
            }
          }}
          collapsible
          collapsed={collapsed}
        >
          <div className="logo">
            <NavLink to={"/"}>
              <img
                style={{ zIndex: "-2" }}
                className="m-auto d-block pt-3"
                width={132}
                src={mainlogo}
                alt=""
              />
            </NavLink>
          </div>

          <Menu theme="dark" mode="inline">
            <Menu.Item key={"memberprofile"}>
              <NavLink to={"/dashboard"}>
                <div className="text-start">
                  <UserOutlined />
                  <span className="align-middle text-center">
                    {" "}
                    {spanish ? "Perfil" : "Profile"}
                  </span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key="d1">
              <NavLink to={"/dashboard/info"}>
                <div className="text-start">
                  <DashboardOutlined />
                  <span className="align-middle">
                    {spanish ? "Tablero" : "Dashboard"}
                  </span>
                </div>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="pack">
              <NavLink to={"packages"}>
                <div className="text-start">
                  <MedicineBoxOutlined />
                  <span className="align-middle">
                    {spanish
                      ? "Historial de Suscripciones"
                      : "Subscription History"}
                  </span>
                </div>
              </NavLink>
            </Menu.Item>

            <Menu.Item key={"accountseting"}>
              <NavLink to={"settings/basic"}>
                <div className="text-start">
                  <SettingOutlined />
                  <span className="align-middle">
                    {spanish ? "Ajustes" : "Account Settings"}
                  </span>
                </div>
              </NavLink>
            </Menu.Item>
          </Menu>
        </Sider>
        <Layout style={{ zIndex: "1" }} className="site-layout">
          <Header
            className="site-layout-background mb-1  "
            style={{
              padding: 0,
            }}
          >
            {React.createElement(
              collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
              {
                className: "trigger float-start pt-4",
                onClick: () => setCollapsed(!collapsed),
              }
            )}
            <div
              style={{ float: "right", height: 64, padding: 3 }}
              className="float-right text-start pe-4 "
            >
              <Dropdown
                className="text-dark ps-3 "
                arrow={{
                  pointAtCenter: true,
                }}
                overlay={menu}
                placement="bottom"
              >
                <a onClick={(e) => e.preventDefault()}>
                  <Space style={{ minWidth: "100px" }}>
                    <img
                      width={35}
                      height={35}
                      className="rounded-circle me-2"
                      src="https://images.unsplash.com/photo-1481349518771-20055b2a7b24?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=939&q=80"
                    />
                    <Spin spinning={isloading} />
                    <div className="text-"> {username}</div>
                  </Space>
                </a>
              </Dropdown>
            </div>
          </Header>
          <Content style={{ backgroundColor: "#ddeefc" }}>
            <div
              className="site-layout-background "
              style={{
                minHeight: 360,
                backgroundColor: "#ddeefc",
              }}
            >
              {show ? (
                <Routes>
                  <Route
                    path="/"
                    element={<MemberProfile spanish={spanish} />}
                  />
                  <Route
                    path="/packages"
                    element={<Subbedpackages spanish={spanish} />}
                  />
                  <Route
                    path="/info"
                    element={<Dashboardpage spanish={spanish} />}
                  />

                  <Route
                    path="settings/*"
                    element={<MemberSettingsmain spanish={spanish} />}
                  />
                </Routes>
              ) : (
                <div style={{ position: "relative", height: "100vh" }}>
                  <Spin
                    style={{ position: "absolute", top: "40%", left: "50%" }}
                  />
                </div>
              )}
            </div>
          </Content>
          <Footer
            style={{
              textAlign: "center",
              backgroundColor: "#ddeefc",
            }}
          >
            Copyright © 2021 Telhealth
          </Footer>
        </Layout>
      </Layout>
    </div>
  );
}

export default MembDashboard;
