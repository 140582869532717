import React from "react";
import Packages from "../packages/package";
import photo from "../img/cold.png";
import MainMembershipincludes from "../Local/components/membershipincludes";
import Details from "./details";
import Joinus from "../Local/components/joinus";
import { useState } from "react";
import { API } from "../../config/urls";
import { useEffect } from "react";
import axios from "axios";
import { imgconvert } from "../../helpers/imgconverter";
import { convertlr } from "../../helpers/covertlr.ts";
function ServicesMain() {
  const [data, setdata] = useState();
  useEffect(() => {
    axios(API.getservices).then((x) => {
      setdata(x.data.data);
      console.log(x.data.data);
    });
  }, []);
  return (
    <>
      <div className="position-relative bghex" style={{}}>
        <div className="container-lg p-0 py-5">
          <div style={{ paddingTop: 70 }} className="">
            <div className={` ${1 == 1 ? "" : "order-1"}`}>
              <div className="fw-bolder fs-1 py-4">
                {data && data.first && data.first.content.header}
              </div>

              <div className="">
                <div
                  style={{ maxWidth: 700, margin: "auto" }}
                  className="lead pcenter"
                  dangerouslySetInnerHTML={{
                    __html:
                      data && data.first && data.first.content.description,
                  }}
                ></div>
              </div>
              <div>
                <div className="fs-1 text-center">
                  <button className="btn homebtn px-5 fs-4 fw-bold rounded-5 m-4">
                    Become a Member
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-white p-3">
          {" "}
          <Packages data={data && data.second} />
        </div>
      </div>
      <div className="p-3">
        {data &&
          data.third.menu.menus.map((x) => {
            const y = x.contents[0];
            return (
              <Details
                image={convertlr(y.img[0].pos)}
                header={y.header}
                body1={y.subheader}
                body2={y.description}
                icon1={imgconvert(y.img[0].img.url)}
              />
            );
          })}
      </div>

      <div>
        <Joinus />
      </div>
    </>
  );
}

export default ServicesMain;
