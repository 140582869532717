import styled from "styled-components";
export const Fbutton = styled.button`
  background-color: #283779;
  color: white;
  border: 0px;
  padding: 10px;
`;
export const Downscrollbutton = styled.div`
  border: 3px solid #283779;
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 10px;
  left: 50%;
  font-size: 10px;
  font-weight: 700;
`;
export const Blue = styled.span`
  color: #283779;
`;
export const LBlue = styled.span`
  color: #b2dded;
`;
export const Roundedsmallimg = styled.img`
  width: 50px;
`;
export const Lol = styled.div`
  width: 100px;
  height: 100px;
  background-color: #98e1b7;
  margin: auto;
  padding-top: 10px;
  pading-left: 20px;
`;
export const Lbluebg = styled.div`
  background-color: #a2b3ff;
`;
export const Fmenuitem = styled.h6`
  padding-top: 5px;
  text-align: left;
  color: black;
`;

export const LBluetext = styled.h5`
  color: #b2dded;
`;

export const Progressbar = styled.div`
  color: #b2dded;
`;
export const Shead = styled.div`
  font-size: 25px;
  font-weight: 600;
  margin: 10px;
  max-width: 500px;
  text-align: left;
  margin: auto;
`;
export const Sbody = styled.div`
  overflow: hidden;
  max-width: 500px;
  font-size: 14px;
  font-weight: 400;
  margin: 10px;
  text-align: left;
  line-height: 2rem;
  margin: auto;
`;
