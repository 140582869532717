import React from "react";
import Howitworks from "../Home/Components/howitworks";
import Joinus from "../Local/components/joinus";

function Howitworksmain() {
  return (
    <div style={{ paddingTop: 60 }}>
      <Howitworks />
      <Joinus />
    </div>
  );
}

export default Howitworksmain;
