import axios from "axios";
import React, { useEffect, useState } from "react";
import { API } from "../../config/urls";
import { imgconvert } from "../../helpers/imgconverter";
import { Sbody } from "../style";

function Ourprovidersmain() {
  const [data, setdata] = useState();

  useEffect(() => {
    axios(API.getour_providers)
      .then(function (response) {
        setdata(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  }, []);
  return (
    <div>
      <div className="position-relative bghex" style={{}}>
        <div className="container-lg p-0 py-5">
          <div style={{ paddingTop: 80 }} className="row">
            <div className="text-center">
              <h1 className="">{data && data.header}</h1>
              <div
                className="lead "
                dangerouslySetInnerHTML={{ __html: data && data.description }}
              >
                {}
              </div>
              <div>
                <button
                  onClick={() => {
                    window.scroll(0, 1000);
                  }}
                  className=" homebtn btn m-3"
                >
                  {data && data.button}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="py-5 container ">
        <div className="row">
          <div
            className={`col bg-light rounded-5 p-3 ${
              data &&
              data.info &&
              data.info.contents[0].img[0] &&
              data.info.contents[0].img[0].pos
                ? ""
                : "order-1"
            }`}
          >
            <div>
              <h1 className="text-start">
                {data && data.info && data.info.contents[0].header}
              </h1>

              <div
                style={{ lineHeight: 2 }}
                className="text-start fs-5 opacity-75"
                dangerouslySetInnerHTML={{
                  __html:
                    data && data.info && data.info.contents[0].description,
                }}
              ></div>
            </div>
          </div>
          <div className="col">
            <img
              height={400}
              src={imgconvert(
                data &&
                  data.info &&
                  data.info.contents[0].img[0] &&
                  data.info.contents[0].img[0].img.url
              )}
              alt=""
            />
          </div>
        </div>
      </div>
      <div className="py-2 container  ">
        <div className="py-2 h1 fw-bold">
          {data && data.who_makes_us && data.who_makes_us.header}
        </div>
        <Sbody className="lead text-center py-3">
          {data && data.who_makes_us && data.who_makes_us.description}
        </Sbody>
        <div className="row py-4 g-4 bg-light rounded-5">
          {data &&
            data.who_makes_us &&
            data.who_makes_us.menu.menus.map((x) => {
              return (
                <div
                  key={x.id}
                  className="col-md m-2 rounded-5 p-0 bg-white shadow-lg"
                >
                  <img
                    style={{
                      borderTopRightRadius: 25,
                      borderTopLeftRadius: 25,
                      borderBottomRadius: 0,
                      objectFit: "cover",
                    }}
                    width={"100%"}
                    height={200}
                    className=""
                    src={imgconvert(x.contents[0].img[0].img.url)}
                    alt=""
                  />
                  <div className="p-3 ">
                    <div className="row g-4">
                      <div className="col">
                        <div className="fw-bold fs-5 text-start">
                          {x.contents[0].header}
                        </div>
                        <div
                          style={{ fontSize: 12 }}
                          className="lead text-start"
                        >
                          More than 10 years of experience
                        </div>
                      </div>
                      <div className="col ">
                        <button className="btn homebtn">Learn More</button>
                      </div>
                    </div>
                    <div
                      className="text-start p-2"
                      dangerouslySetInnerHTML={{
                        __html: x.contents[0].description,
                      }}
                    >
                      {}
                    </div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}

export default Ourprovidersmain;
