import React from "react";
import Getintouch from "../../Local/components/getintouch";
import { Sbody, Shead } from "../../style";
function Indv(props) {
  return (
    <div style={{ height: "inherit" }} className="col-sm">
      <div style={{ height: "100%" }}>
        <div
          style={{ backgroundColor: `${props.bg}` }}
          className="shadow-lg rounded-5 p-4 h-100"
        >
          <div className="fw-bold lead text-start">{props.head}</div>
          <div
            style={{ lineHeight: 2, fontSize: "12px" }}
            className="text-start my-3"
          >
            {props.body}
          </div>
          <div>
            <button className="bg-white rounded-3 btn shadow p-2 mt-2 w-100 fw-bold px-3">
              Learn More
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
function Indv1(props) {
  const style = `col-md p-4 ${props.order === "1" ? "order-2" : ""}`;
  return (
    <div className="row bg-white rounded-5 p-3 my-5 m-2 g-3 ">
      <div className={style}>
        <div>
          <img
            src={props.img}
            className="rounded-5 shadow-lg d-block mt-auto"
            style={{ maxHeight: "300px", width: "90%", objectFit: "cover" }}
            alt=""
          />
        </div>
      </div>
      <div className="col p-4">
        <Shead>{props.head}</Shead>
        <Sbody>{props.body}</Sbody>{" "}
        <button
          onClick={() => {
            window.location.replace(props.link);
          }}
          className="homebtn btn mt-3"
        >
          Learn More
        </button>
      </div>
    </div>
  );
}
function Medicalcaremain() {
  return (
    <div>
      <div className="position-relative bghex" style={{}}>
        <div className="container-md px-1 p-0 py-5">
          <div style={{ paddingTop: 80 }} className="">
            <div className="m-auto p-4" style={{ maxWidth: 600 }}>
              <h1>Medical TeleHealth Remedies</h1>
              <div style={{ lineHeight: 2 }} className="lead m-3 lineheight-2">
                Our Medical TeleHealth services make it easy for members to
                access mental health care on their schedule while driving down
                overall healthcare costs.
              </div>
              <div>
                <button className="btn homebtn fw-bold m-4 ">
                  Get In Touch With Us
                </button>
              </div>
            </div>
            <div className="row g-4 m-3">
              <Indv
                bg={"#f7e9b4"}
                head="Talk Therapy
"
                body="Teletherapy removes the barriers of traditional in-person care, providing much-needed mental health services from the comfort and privacy of home."
              />
              <Indv
                bg={"#b4f0f7"}
                head="
Teen Therapy"
                body="Our teen teletherapy program gives employees access to the mental healthcare their children need – and gives your staff the peace of mind they crave."
              />
              <Indv
                bg={"#f1a1a1"}
                head="Psychiatry"
                body="Our psychiatrists and psychiatric nurse practitioners can help with many of the most common mental health concerns."
              />
            </div>
            <div className="py-5">
              <Indv1
                order="1"
                link={"/talktherapy"}
                img={
                  "https://images.squarespace-cdn.com/content/v1/5aa96c579772aea9adaa2ef7/1589463218100-OVLWWSX7F8S2O7AAYEXY/MobileHealthcareApps_Healthtap_520.png?format=1000w"
                }
                head={"Talk Therapy"}
                body={`Every business owner has witnessed the effects of behavioral health issues in the workplace. They drag down productivity and erode company culture. If left untreated, mental health concerns can even drive up medical costs.

                ​
                
                Teletherapy removes the barriers of traditional in-person care, providing much-needed mental health services from the comfort and privacy of home.`}
              />
              <Indv1
                link={"/talktherapy"}
                img={
                  "https://images.squarespace-cdn.com/content/v1/5aa96c579772aea9adaa2ef7/1589463218100-OVLWWSX7F8S2O7AAYEXY/MobileHealthcareApps_Healthtap_520.png?format=1000w"
                }
                head={"Talk Therapy"}
                body={`Every business owner has witnessed the effects of behavioral health issues in the workplace. They drag down productivity and erode company culture. If left untreated, mental health concerns can even drive up medical costs.

                ​
                
                Teletherapy removes the barriers of traditional in-person care, providing much-needed mental health services from the comfort and privacy of home.`}
              />
              <Indv1
                order="1"
                link={"/talktherapy"}
                img={
                  "https://images.squarespace-cdn.com/content/v1/5aa96c579772aea9adaa2ef7/1589463218100-OVLWWSX7F8S2O7AAYEXY/MobileHealthcareApps_Healthtap_520.png?format=1000w"
                }
                head={"Talk Therapy"}
                body={`Every business owner has witnessed the effects of behavioral health issues in the workplace. They drag down productivity and erode company culture. If left untreated, mental health concerns can even drive up medical costs.

              ​
              
              Teletherapy removes the barriers of traditional in-person care, providing much-needed mental health services from the comfort and privacy of home.`}
              />
            </div>
          </div>
        </div>
      </div>
      <div>
        <div className="py-5 container">
          <div className="h1 fw-light">Medical Care  for a Modern World</div>
          <div style={{ maxWidth: 500 }} className="m-auto my-4">
            In today’s world, employees expect their benefits to meet their
            health, wellness and financial needs, while remaining accessible and
            easy-to-understand. Striving to meet expectations, employers are
            left with increased healthcare premiums and decreased access to
            care. So how does everyone win?
          </div>
          <div className="fw-bold fs-5">Enter, Virtual Medical Care .</div>
          <div>
            {" "}
            <button className="btn homebtn m-2">Learn More</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Medicalcaremain;
