import React, { Component } from 'react'
import Indquery from './components/indquery'

export class Qquery extends Component {
  render() {
    return (
      <div>

<Indquery/>

      </div>
    )
  }
}

export default Qquery