import axios from "axios";
import { useEffect, useState } from "react";
import { API } from "../../config/urls";
import Faqcomponent from "./faqcomponent";
function Faqmain() {
  const [data, setdata] = useState();
  useEffect(() => {
    axios(API.getcontact).then((x) => {
      setdata(x.data.data);
      console.log(x.data.data);
    });
  }, []);

  return (
    <div>
      <div className="position-relative bghex py-4">
        <div style={{ paddingTop: "100px" }}>
          <div className="container-md">
            <div style={{ maxWidth: 400 }} className="row m-auto">
              <div className="col">
                <div className="lead fs-6">Ask a Question?</div>
                <div className="fs-1 fw-bold">Contact Us</div>
                <div
                  className="pcenter"
                  dangerouslySetInnerHTML={{
                    __html: data && data.content.description,
                  }}
                ></div>
              </div>
            </div>
            <div style={{ maxWidth: 700 }} className="row g-3 m-auto my-3">
              <div className="col">
                <div className="p-3 shadow-lg rounded-5 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ width: 50, height: 50 }}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                    />
                  </svg>

                  <div className="fs-3 fw-bold  p-2 ">
                    +{data && data.phone_number}
                  </div>
                </div>
              </div>
              <div className="col">
                <div className="p-3 shadow-lg rounded-5 ">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={1.5}
                    stroke="currentColor"
                    style={{ width: 50, height: 50 }}
                  >
                    <path
                      strokeLinecap="round"
                      d="M16.5 12a4.5 4.5 0 11-9 0 4.5 4.5 0 019 0zm0 0c0 1.657 1.007 3 2.25 3S21 13.657 21 12a9 9 0 10-2.636 6.364M16.5 12V8.25"
                    />
                  </svg>

                  <div className="fs-3 fw-bold  p-2 ">{data && data.email}</div>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>
      <div>
        <Faqcomponent />
      </div>
    </div>
  );
}

export default Faqmain;
