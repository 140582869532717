import React from "react";
import Paymentform, { Stripepage } from "./paymentform";

import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { Packages } from "../../main";

export default function Payment(props) {
  const [state, actions] = useContext(Packages);
  const navigate = useNavigate();
  return (
    <div>
      <div className="row text-dark fs-6 bg-light mx-3 py-3">
        <div className="w-100 lead fs-6 text-start">
          Your first Payment of{" "}
          <strong className="fs-5 fw-bold">
            $
            {state.terms === "yearly" ? (
              <>{state.price.toFixed(2)}</>
            ) : (
              <>{(state.price / 12).toFixed(2)}</>
            )}
          </strong>{" "}
          will be charged today, {new Date().toLocaleDateString()}.{" "}
          <strong>
            You are buying <strong className="fs-5">{state.title}</strong> for{" "}
            <strong className="fs-5">
              $
              {state.terms === "yearly" ? (
                <>{state.price.toFixed(2)}</>
              ) : (
                <>{(state.price / 12).toFixed(2)}</>
              )}
            </strong>{" "}
            / {state.terms === "yearly" ? "Year" : "Month"}. By clicking 'Buy
            Subscription' you agree to our{" "}
            <a className="greentxt text-decoration-none">Terms of Service.</a>{" "}
          </strong>
        </div>
      </div>
      <div>
        <Stripepage publishkey={props.promise} />
      </div>
    </div>
  );
}
