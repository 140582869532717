import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { API } from "../../config/urls";
import Joinus from "../Local/components/joinus";
import Minicompare from "./minicompare";
import Ploveline from "./Ploveline";

import Whyloveuscompo from "./whyloveuscompo";
function Whyusmain() {
  const [data, setdata] = useState();
  useEffect(() => {
    axios(API.getwhy_us).then((x) => {
      setdata(x.data.data);
    });
  }, []);
  return (
    <div>
      <div className="position-relative bghex" style={{}}>
        <div className="container-lg p-0 py-5">
          <div style={{ paddingTop: 80 }} className="row">
            <div className="lead">{data && data.header}</div>
            <div style={{ fontSize: 60 }} className="h1">
              {data && data.sub_header}
            </div>
          </div>
        </div>
      </div>{" "}
      <div className="py-5 container">
        <div className="h1 fw-light">{data && data.content.name}</div>
        <div
          className="m-auto my-4"
          dangerouslySetInnerHTML={{
            __html: data && data.content && data.content.description,
          }}
        >
          {}
        </div>
        <div className="fw-bold fs-5">
          {" "}
          {data && data.content && data.content.sub_heading}
        </div>
        <div>
          {" "}
          <button className="btn homebtn m-2"> Learn More</button>
        </div>
      </div>
      <div className="bghex py-5">
        <Whyloveuscompo data={data && data.menu} />
      </div>
      <Ploveline data={data && data.briefs && data.briefs[0]} />
      <Minicompare data={data && data.briefs && data.briefs[1]} />
      <Joinus />
    </div>
  );
}

export default Whyusmain;
