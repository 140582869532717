import {
  CardElement,
  Elements,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { message } from "antd";
import moment from "moment";
import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Packages } from "../../main";
import "./style.css";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#4a4a4a",
      color: "#4a4a4a",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#4a4a4a",
      },
      "::placeholder": {
        color: "#4a4a4a",
      },
    },
    invalid: {
      iconColor: "#ff3737",
      color: "#ff3737",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled }) => (
  <button
    className={`SubmitButton ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? "Processing..." : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    {children}
  </div>
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const navigate = useNavigate();
  const [state, actions] = useContext(Packages);
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);

  const [billingDetails, setBillingDetails] = useState({
    email: "",
    name: "",
    Address: "",
  });

  const handleSubmit = async (event) => {
    setProcessing(true);
    event.preventDefault();
    const postdata = (propsdata) => {
      if (propsdata.error) {
        setProcessing(false);
        setError(propsdata.error);
        return;
      }
      const axios = require("axios");
      let data = JSON.stringify({
        email: props.state2.Email,
        first_name: props.state2.Firstname,
        middle_name: props.state2.middle_name,
        last_name: props.state2.Lastname,
        gender: props.state2.gender,
        date_of_birth: moment(props.state2.Dob).format("MM-DD-YYYY"),
        address_1: props.state2.Address,
        address_2: props.state2.address_2,
        zip: props.state2.Zip,
        city: props.state2.City,
        state: props.state2.State,
        language: props.state2.language,
        phone_number: props.state2.Mobileno,
        package: props.state2.id,
        recurring: props.state2.Recurring === "false" ? false : true,
        terms: props.state2.terms === "monthly" ? "Monthly" : "Yearly",
        language: props.state2.language,
        card_source: propsdata.token.id,
        del_keys: window.localStorage.getItem("del_keys"),
      });

      let config = {
        method: "post",
        url: process.env.REACT_APP_APPURL + "external/create_membership",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          navigate("/account-created");
        })
        .catch((error) => {
          setProcessing(false);
          console.log(error);
          function ifcarderror() {
            const x = error.response.data[0];
            let y = x.search(":");
            message.error(x.slice(y + 1));
          }
          function unactive() {
            message.error("There was an error.");
          }
          typeof error.response.data[0] === `string`
            ? ifcarderror()
            : unactive();
        });
    };
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    if (error) {
      card.focus();
      return;
    }

    if (cardComplete) {
    }

    const cardElement1 = elements.getElement("card");

    stripe.createToken(cardElement1).then((payload) => {
      setProcessing(true);
      postdata(payload);
    });
  };

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup pt-2 py-0">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <div>{error && <ErrorMessage>{error.message}</ErrorMessage>}</div>
      </fieldset>

      <SubmitButton processing={processing} error={error} disabled={!stripe}>
        Pay $
        {state.terms === "yearly" ? (
          <>{state.price.toFixed(2)}</>
        ) : (
          <>{(state.price / 12).toFixed(2)}</>
        )}
      </SubmitButton>
      <div style={{ height: 60 }} className="container ">
        <button
          onClick={() => {
            state.page = "2";
          }}
          className="btn float-start editplanbtn rounded-pill mx-2"
        >
          Previous
        </button>
      </div>
    </form>
  );
};

const ELEMENTS_OPTIONS = {};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export const Stripepage = (props) => {
  const stripePromise = loadStripe(props.publishkey);
  const [state, actions] = useContext(Packages);

  return (
    <div className="AppWrapper p-5">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm state2={state} payprice={state.price} />
      </Elements>
    </div>
  );
};
