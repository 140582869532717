import React from "react";

function Pricingbase({ data, name, price }) {
  let x = data && data.includes_list;
  let y = document.getElementsByTagName("li");
  return (
    <div style={{ maxWidth: 800 }} className="container-sm py-5">
      <h1>How {name} is Priced ?</h1>
      <div className="row ">
        <div className=" col p-0 m-3 shadow-lg rounded-5">
          <div>
            <div
              style={{
                backgroundColor: "#36b3aa",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
              }}
              className="text-white p-2 fw-bold"
            >
              Yearly
            </div>
            <div style={{ backgroundColor: "#c6f4d1" }} className="p-3">
              <div className="lead">Membership</div>
              <div className="fw-bold fs-2">
                $ {price && price} <span className="lead">/Yearly</span>
              </div>
              <div>
                <button
                  onClick={() => {
                    window.location.replace("/packages");
                  }}
                  className="homebtn btn m-2 lead"
                >
                  Join Us
                </button>
              </div>
            </div>
            <div className="p-3">
              <div className="fw-bold fs-5">
                {data && data.includes_list.name}
              </div>
              <div>
                <div
                  className={"spac"}
                  dangerouslySetInnerHTML={{
                    __html: data.includes_list.description,
                  }}
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div className=" col p-0 m-3 shadow-lg rounded-5">
          <div>
            <div
              style={{
                backgroundColor: "#36b3aa",
                borderTopRightRadius: 20,
                borderTopLeftRadius: 20,
              }}
              className="text-white p-2 fw-bold"
            >
              Monthly
            </div>
            <div style={{ backgroundColor: "#c6f4d1" }} className="p-3">
              <div className="lead">Membership</div>
              <div className="fw-bold fs-2">
                $ {price && (price / 12).toFixed(2)}{" "}
                <span className="lead">/Monthly</span>
              </div>
              <div>
                <button
                  onClick={() => {
                    window.location.replace("/packages");
                  }}
                  className="homebtn btn m-2 lead"
                >
                  Join Us
                </button>
              </div>
            </div>
            <div className="p-3">
              <div className="fw-bold fs-5">
                {" "}
                {data && data.includes_list.name}
              </div>
              <div
                className={"spac"}
                dangerouslySetInnerHTML={{
                  __html: data.includes_list.description,
                }}
              ></div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <button className="btn homebtn m-2 fw-bold ">
          Book An Appointment
        </button>
        <div style={{ fontSize: 10 }}>{data.termscondn} </div>
        <div style={{ fontSize: 10 }}>* Terms And Conditions Apply.</div>
      </div>
    </div>
  );
}

export default Pricingbase;
