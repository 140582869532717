import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import "./style/step1.module.css";
import Slider from "./slider";
import { Packages } from "../main";
import { useContext } from "react";
import { Spin } from "antd";

const Step1 = (props) => {
  function packagesdetails() {
    const axios = require("axios");

    let config = {
      method: "get",
      url: process.env.REACT_APP_APPURL + "external/getpackages/",
      headers: {
        Accept: "application/json",
        Authorization: "Token " + process.env.REACT_APP_OFFICE_TOKEN,
      },
    };

    axios(config)
      .then((response) => {
        setposts(response.data);
        setprocessing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    packagesdetails();
  }, []);
  const [posts, setposts] = useState();
  const [processing, setprocessing] = useState(true);
  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm();
  const [state, actions] = useContext(Packages);
  const Navigate = useNavigate();
  const onSubmit = (data) => {
    let c = null;
    for (let i = 0; i < posts.length; i++) {
      if (posts[i].id == data.pid) {
        c = posts[i];
        break;
      }
    }
    actions({ ...state, ...c });
    Navigate("/packages/step2");
  };

  let content =
    posts &&
    posts.map((x) => (
      <div style={{}} className="col p-2 m-0" key={x.id}>
        <input
          className="checkoutinput"
          hidden
          {...register("pid", { required: true })}
          type="radio"
          value={x.id}
          id={x.id}
          defaultChecked={state.id}
        />
        <label className="Package shadow-lg checkoutlabel " htmlFor={x.id}>
          <div
            className="w-100 px-5 py-2  text-white fw-bold"
            style={{
              backgroundColor: "#283779",
              borderRadius: "20px 20px 0px 0px",
              letterSpacing: "2px",
            }}
          >
            {state.terms === "monthly" ? "Monthly Plan" : "Yearly Plan"}
          </div>
          <div className="text-center"> {x.plan}</div>

          <div className="mt-0 p-0" style={{ fontSize: "50px" }}>
            $
            {state.terms === "yearly" ? (
              <>
                {Number((x.price - (x.price % 1)).toFixed(0))}
                <sup className="fs-1">
                  .
                  {Number((x.price % 1) * 100) === 0
                    ? "00"
                    : Number(((x.price % 1) * 100).toFixed(0))}
                </sup>
              </>
            ) : (
              <>
                {Number((x.price / 12 - ((x.price / 12) % 1)).toFixed(0))}
                <sup className="fs-1">
                  .
                  {Number(((x.price / 12) % 1) * 100) === 0
                    ? "00"
                    : Number((((x.price / 12) % 1) * 100).toFixed(0))}
                </sup>
              </>
            )}
            <span className="fs-6">
              {state.terms === "monthly" ? "/MO" : "/YR"}
            </span>
          </div>
        </label>
      </div>
    ));
  return (
    <div style={{ background: "" }} className=" mt-5 m-auto">
      <form
        style={{ boxShadow: "0 1rem 3rem #283779", borderRadius: "29px" }}
        onSubmit={handleSubmit(onSubmit)}
      >
        <div
          style={{
            backgroundColor: "#283779",
            borderRadius: "29px 29px 0px 0px",
            letterSpacing: "2px",
          }}
          className=" text-center text-white p-3"
        >
          <span className="fw-bold fs-4 float-start"> 1</span>
          <span className="text-center fw-bold fs-4">Choose a package</span>
        </div>
        <div>
          <div className="bg-light p-5">
            <div className="container">
              <Slider />
            </div>
            <div className="row">
              {processing ? (
                <div style={{ height: 200, margin: "auto" }}>
                  <Spin size="large" style={{ margin: 100 }} />
                </div>
              ) : (
                content
              )}
            </div>

            <div className="text-danger fw-bold">
              {errors.Packagename?.type === "required" &&
                "⚠ Please Choose A Package"}
            </div>
            <div className="container w-75 pt-5">
              <input className="checkoutinput" type="submit" value="Next" />
            </div>
          </div>
        </div>

        <div
          style={{
            backgroundColor: "#283779",
            letterSpacing: "2px",
            borderRadius: "0px 0px 29px 29px",
          }}
          className=" stepsbg text-center"
        >
          <div className="fs-4  p-3 ">
            <strong className="float-start">2</strong>Customer Information
            <div className="border border-bottom rounded mt-4 w-50 m-auto"></div>
          </div>
          <div
            style={{
              backgroundColor: "#283779",
              letterSpacing: "2px",
              borderRadius: "0px 0px 29px 29px",
            }}
            className="stepsbg fs-4  p-3"
          >
            <strong className="float-start">3</strong>
            Review Information
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step1;
