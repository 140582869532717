import { Button, message, Spin } from "antd";
import moment from "moment";
import React, { useEffect, useState } from "react";
import useId from "../../../useId";
import useToken from "../../../useToken";
import { Stripepage } from "./payment/main";
import Spanishname from "../spanishname.jsx";
function Renewpage({ spanish }) {
  const { Id, setId } = useId();
  const { token } = useToken();
  const lastitem = Id.membership.membership_history.slice(-1);
  const [data, setdata] = useState();
  const [selected, setselected] = useState(null);
  const [processing, setprocessing] = useState(true);
  const [stripepage, setstripepage] = useState(false);
  const [promise, setpromise] = useState();
  const [selectedprice, setselectedprice] = useState(0);
  const [recurring, setrecurring] = useState();
  const borderblack = "1px solid #002140";
  useEffect(() => {
    const axios = require("axios");
    let data = JSON.stringify({
      package_id: lastitem[0].package.id,
    });

    let config = {
      method: "post",
      url:
        process.env.REACT_APP_EXTERNAl_APPURL + "v1/helpers/get-package-adjusted-price/",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token" + " " + `${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        setdata(response.data);
        setprocessing(false);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  useEffect(() => {
    const axios = require("axios");

    let config = {
      method: "get",
      url: process.env.REACT_APP_EXTERNAl_APPURL + "v1/helpers/stripe-publish-key/",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",

        Authorization: "Token" + " " + `${token}`,
      },
    };

    axios(config)
      .then((response) => {
        setpromise(response.data.publish_key);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <div>
      <div style={{ maxWidth: "500px", margin: "auto" }}>
        <div className="container bg-white my-5 mb-2 mt-2 px-4 py-2 pt-3 rounded-4 shadow-lg">
          <div className="bg-wnite ">
            <div
              style={{ borderRadius: "20px" }}
              className="row bdarkblue p-4 text-white py-2 "
            >
              <div className="col">
                <div>
                  {stripepage
                    ? spanish
                      ? "Plan seleccionado"
                      : "Selected Plan"
                    : spanish
                    ? "Plan anterior"
                    : "Previous Plan"}
                </div>
                <h6 className="text-white">
                  {spanish ? (
                    <Spanishname e={lastitem[0].package.plan} />
                  ) : (
                    lastitem[0].package.plan
                  )}
                  : (
                  {stripepage
                    ? selected === "Yearly"
                      ? `${spanish ? "Anual" : "Yearly"}`
                      : `${spanish ? "Mensual" : "Monthly"}`
                    : lastitem[0].terms === "Yearly"
                    ? `${spanish ? "Anual" : "Yearly"}`
                    : `${spanish ? "Mensual" : "Monthly"}`}
                  )
                </h6>
              </div>
              <div className="col m-auto text-end">
                <h6 className="text-white">
                  <span className="h5">
                    $
                    {stripepage
                      ? Number(selectedprice).toFixed(2)
                      : lastitem[0].calculated_price}
                  </span>
                  /
                  {stripepage
                    ? selected === "Yearly"
                      ? `${spanish ? "Anual" : "Yearly"}`
                      : `${spanish ? "Mensual" : "Monthly"}`
                    : lastitem[0].terms === "Yearly"
                    ? `${spanish ? "Año" : "YR"}`
                    : `${spanish ? "Mes" : "MO"}`}
                </h6>
              </div>
            </div>
            <div className=" bg-white row p-4 px-2 pb-0">
              <div className="col">
                <div className="fs-6">
                  {" "}
                  {spanish
                    ? "Fecha de inicio de la suscripción"
                    : "Subscription Start Date"}
                </div>{" "}
                <div>
                  {stripepage
                    ? moment().format("L")
                    : lastitem[0].benefit_start}
                </div>
              </div>
              <div className="col text-end">
                <div className="fs-6">
                  {" "}
                  {spanish
                    ? "Fecha de finalización de la suscripción"
                    : "Subscription End Date"}
                </div>{" "}
                <div>
                  {stripepage
                    ? selected === "Yearly"
                      ? moment().add(365, "days").calendar()
                      : moment().add(30, "days").calendar()
                    : lastitem[0].benefit_end}
                </div>
              </div>
            </div>
          </div>
        </div>
        {stripepage ? (
          <div
            className="m-auto shadow-lg bg-white p-3 rounded-5"
            style={{ maxWidth: 500 }}
          >
            <Stripepage
              spanish={spanish}
              publishkey={promise}
              recurring={recurring === "yes" ? true : false}
              package={lastitem[0].package.id}
              terms={selected}
              price={Number(selectedprice).toFixed(2)}
            />
            <div></div>
            <button
              style={{ backgroundColor: "#002140" }}
              onClick={() => {
                setstripepage(false);
              }}
              className="rounded-4 text-white lead fs-6 btn text-end btn  d-block me-0 m-auto my-4"
            >
              {spanish ? "Botón de retroceso" : " Back"}
            </button>
          </div>
        ) : (
          <div>
            {processing ? (
              <Spin />
            ) : (
              <div>
                <div className="fw-bold h3 text-center">
                  {spanish ? "Renovar los términos para" : "Renew Terms for"}{" "}
                  {spanish ? (
                    <Spanishname e={lastitem[0].package.plan} />
                  ) : (
                    lastitem[0].package.plan
                  )}{" "}
                  :
                </div>
                <div
                  style={{
                    maxWidth: 500,
                  }}
                  className="container rounded-5 bg-white shadow-lg p-4"
                >
                  <div className="row ">
                    <div
                      onClick={() => {
                        setselected("Yearly");
                        setselectedprice(data.price);
                      }}
                      style={{
                        borderRadius: 16,
                        border: selected === "Yearly" ? borderblack : null,
                      }}
                      className=" shadow-lg col  m-2 p-0"
                    >
                      <div>
                        <div
                          style={{
                            borderTopRightRadius: 15,
                            borderTopLeftRadius: 15,
                            backgroundColor: "#002140",
                          }}
                          className=" text-white text-center fw-bold p-1  ps-2"
                        >
                          {spanish ? "Anual" : "Annual"}
                        </div>
                        <div className="fw-bold h4 text-center p-3">
                          ${Number(data.price).toFixed(2)} /{" "}
                          {spanish ? "al Año" : "Year"}
                        </div>
                        <div>
                          <div
                            style={{ width: 150, backgroundColor: "#002140" }}
                            className="rounded-5 text-center shadow  p-1  m-auto text-white mb-3"
                          >
                            {spanish ? "Los más populares" : " Most Popular"}
                          </div>
                          <div className="p-2 text-center">
                            ${data.price}{" "}
                            {spanish
                              ? "se facturarán anualmente como pago recurrente."
                              : "will be billed annually as recurring payment."}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      onClick={() => {
                        setselected("Monthly");
                        setselectedprice((data.price / 12).toFixed(2));
                      }}
                      style={{
                        borderRadius: 16,
                        border: selected === "Monthly" ? borderblack : null,
                      }}
                      className=" shadow-lg col m-2 p-0 "
                    >
                      <div>
                        <div
                          style={{
                            borderTopRightRadius: 15,
                            borderTopLeftRadius: 15,
                            backgroundColor: "#002140",
                          }}
                          className="  text-white text-center fw-bold p-1  ps-2"
                        >
                          {spanish ? "Mensual" : "Monthly"}
                        </div>
                        <div className="fw-bold h4 text-center p-3">
                          ${(data.price / 12).toFixed(2)}/{" "}
                          {spanish ? "al Mes" : "Month"}
                        </div>
                        <div className="p-2 text-center">
                          ${(data.price / 12).toFixed(2)}{" "}
                          {spanish
                            ? "se facturarán mensualmente como pago recurrente."
                            : "will be billed monthly as recurring payment."}
                        </div>
                        <div></div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="h2 fw-bold text-center my-3">
                  {spanish ? "Pagos recurrentes" : "Recurring Payments:"}
                  <div className="mx-3 py-3 row g-5">
                    <div
                      style={{
                        color: recurring === "yes" ? "white" : "#002140",
                        backgroundColor:
                          recurring === "yes" ? "#002140" : "white",
                      }}
                      className=" col lead p-2 rounded-pill "
                      onClick={() => {
                        setrecurring("yes");
                      }}
                    >
                      {spanish ? "Sí" : "Yes"}
                    </div>
                    <div
                      style={{
                        color: recurring === "no" ? "white" : "#002140",
                        backgroundColor:
                          recurring === "no" ? "#002140" : "white",
                      }}
                      className=" col lead p-2 rounded-pill "
                      onClick={() => {
                        setrecurring("no");
                      }}
                    >
                      No
                    </div>
                  </div>
                </div>
              </div>
            )}
            <div>
              <button
                style={{ backgroundColor: "#002140" }}
                onClick={() => {
                  selected === null
                    ? message.error("Select A Renew Term first")
                    : setstripepage(true);
                }}
                className="rounded-5 btn text-white lead fs-6  text-center d-block my-4  m-auto "
              >
                {spanish ? "Continúe con el pago" : "Continue to Payment"}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Renewpage;
