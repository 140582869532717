import styled from "styled-components";
export const Shape = styled.div`
clip-path: polygon(73% 0, 100% 37%, 0 37%, 17% 36%, 17% 50%, 0 37%, 0 0);
    width: 100px;
    height:100px;
    position: absolute;
    top: 9px;
    left: -17px;
    padding:8px;
    color:white;
    font-weight:700;
    background-color:#000f51;
}

`;
export const Packbg = styled.div`
  z-index: -1;
  background-image: url(https://static.vecteezy.com/system/resources/previews/002/368/934/non_2x/green-fluid-abstract-background-free-vector.jpg);

  background-size: cover;
`;
export const Smalltxt = styled.div`
  font-size: 10px;
  text-align: justify;
`;
export const Bigtxt = styled.div`
  font-size: 60px;
  font-weight: 600;
`;
export const PackageButton = styled.div`
  background-color: #000f51;
  width: 60%;
  display: block;
  margin: auto;
  padding: 10px;
  color: white;
  font-weight: 700;
  font-size: 16px;
`;
