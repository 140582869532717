import { notification } from "antd";
import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/urls";
import Joinus from "../Local/components/joinus";
function ContactMain() {
  const [data, setdata] = useState();
  const navigate = useNavigate();
  const openNotification = () => {
    notification.open({
      message: "Thank you for getting in touch!",

      description:
        "We appreciate you contacting us. One of our team member will get back in touch with you soon! Have a great day!",
    });
  };
  useEffect(() => {
    axios(API.getcontact).then((x) => {
      setdata(x.data.data);
    });
  }, []);
  const onSubmit = (data) => {
    axios
      .post(API.addformdata, { data: { ...data, Type: "contact-form" } })
      .then((x) => {
        openNotification();
        navigate("/");
      });
  };
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  return (
    <div>
      <div className="position-relative bghex py-2">
        <div style={{ paddingTop: 100 }}>
          <div className="fs-1 fw-bold">Contact US</div>{" "}
          <div className="lead fs-6">Answer Your questions?</div>
        </div>
      </div>
      <div className="m-2">
        <div className="container-md bg-white rounded-5 p-0 my-4 shadow-lg">
          <div className="row  ">
            <div className="col-sm-4 ">
              <div
                style={{
                  borderTopLeftRadius: 25,
                  borderBottomLeftRadius: 25,
                }}
                className="bg1 h-100"
              >
                <div className="">
                  <div className=" p-3">
                    <div className="fs-4 fw-bold py-3">
                      {" "}
                      Contact Information
                    </div>
                    <div
                      className="pcenter"
                      dangerouslySetInnerHTML={{
                        __html: data && data.content.description,
                      }}
                    ></div>
                    <div className=" fs-6 lead bg-white rounded-5 my-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="m-2"
                        style={{ width: 30, height: 30 }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M10.5 1.5H8.25A2.25 2.25 0 006 3.75v16.5a2.25 2.25 0 002.25 2.25h7.5A2.25 2.25 0 0018 20.25V3.75a2.25 2.25 0 00-2.25-2.25H13.5m-3 0V3h3V1.5m-3 0h3m-3 18.75h3"
                        />
                      </svg>
                      + {data && data.phone_number}
                    </div>
                    <div className=" fs-6 lead  bg-white rounded-5 my-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                        strokeWidth={1.5}
                        stroke="currentColor"
                        className="m-2"
                        style={{ width: 30, height: 30 }}
                      >
                        <path
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
                        />
                      </svg>

                      {data && data.email}
                    </div>

                    <div style={{ height: 390 }}></div>
                    <div>
                      <div className="row">
                        {data &&
                          data.social_media.map((x) => {
                            return (
                              <a href={x.url} key={x.id} className="col">
                                {x.name}
                              </a>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col container py-5 px-5">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="row ">
                  <div className="col">
                    <div className="row w-100">
                      <label className="py-3" htmlFor="fname">
                        First Name:
                      </label>
                      <input
                        className="checkoutinput"
                        type="text"
                        placeholder="First name"
                        id="fname"
                        {...register("first_name", {
                          required: true,
                          maxLength: 80,
                        })}
                      />
                    </div>
                    <div className=" row text-danger  ">
                      {errors.first_name?.type === "required" &&
                        "  Please enter First Name"}
                    </div>
                  </div>

                  <div className="col">
                    <div className="row w-100">
                      <label className="py-3" htmlFor="lname">
                        Last Name:
                      </label>
                      <input
                        className="checkoutinput"
                        id="lname"
                        type="text"
                        placeholder="Last name"
                        {...register("last_name", {
                          required: true,
                          maxLength: 100,
                        })}
                      />
                    </div>

                    <div className=" row text-danger  d-inline ">
                      {errors.last_name?.type === "required" &&
                        "  Please enter Last Name"}
                    </div>
                  </div>
                </div>
                <div className="row ">
                  <div className="col">
                    <div className="row w-100">
                      <label className="py-3" htmlFor="mo">
                        Mobile Number:
                      </label>
                      <input
                        className="checkoutinput"
                        type="text"
                        placeholder="Mobile Number"
                        id="mo"
                        {...register("mobile_number", {
                          maxLength: 12,
                        })}
                      />
                    </div>
                    <div className=" row text-danger  ">
                      {errors.mobile_number?.type === "maxLength" &&
                        "  Please enter a valid Mobile Number"}
                    </div>
                  </div>

                  <div className="col">
                    <div className="row w-100">
                      <label className="py-3" htmlFor="em">
                        Email:
                      </label>
                      <input
                        className="checkoutinput"
                        id="em"
                        type="text"
                        placeholder="Email"
                        {...register("email", {
                          required: true,
                          maxLength: 100,
                          pattern:
                            /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                        })}
                      />
                    </div>

                    <div className=" row text-danger  d-inline ">
                      {errors.email?.type === "required" &&
                        "  Please enter  Email"}
                      {errors.email?.type === "pattern" &&
                        "  Please enter a valid  Email"}
                    </div>
                  </div>
                </div>
                <div className="p-3">
                  <label
                    htmlFor="exampleFormControlTextarea1"
                    className="text-start p-2"
                  >
                    <span className="text-start">Message:</span>
                  </label>
                  <textarea
                    className="form-control text-start"
                    id="exampleFormControlTextarea1"
                    rows={3}
                    defaultValue={""}
                    {...register("message")}
                  />
                </div>
                <div className="container w-75 py-2">
                  <input
                    className="checkoutinput"
                    type="submit"
                    value="Submit"
                  />
                </div>
              </form>{" "}
            </div>
          </div>
        </div>
      </div>{" "}
      <div>
        <Joinus />
      </div>
    </div>
  );
}

export default ContactMain;
