import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Input } from "antd";
import { Button, message } from "antd";
import useId from "../../../useId";
const TextField = Input;
const Step1 = ({ spanish }) => {
  const { Id } = useId();
  const [readonly, setreadonly] = useState(true);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    postdata();
  };
  useEffect(() => {
    setValue("FirstName", Id.first_name);
    setValue("LastName", Id.last_name);
    setValue("MiddleName", Id.middle_name);
    setValue("MobileNumber", Id.phone_number);
    setValue("Email", Id.email);
  }, []);

  const postdata = () => {};
  return (
    <div className="d-none">
      <form
        style={{ fontSize: "13px" }}
        className="w-75  mb-4 m-2 d-block m-auto"
        onSubmit={handleSubmit(onSubmit)}
      >
        <div className="row p-2">
          <div className="col-3 p-0">
            <label htmlFor="FirstName" className=" align-middle text-end fs6 ">
              <span className="text-danger fw-bold fs-5"></span>{" "}
              {spanish ? "Nombre:" : "First Name:"}
            </label>
          </div>
          <div className="col-7 fs6">
            <TextField
              readonly
              size="small"
              style={{ fontSize: "10px" }}
              error={!!errors.FirstName}
              {...register("FirstName", { required: true, maxLength: 100 })}
              className=" me-3"
              label={spanish ? "Nombre" : "First Name"}
              id="FirstName"
              placeholder={spanish ? "Nombre" : "First Name"}
              fullWidth
              InputLabelProps={{ style: { fontSize: "13px" } }}
              InputProps={{ readOnly: readonly, style: { fontSize: "13px" } }}
            />
            <div className=" row text-danger p-auto m-auto fs5">
              {errors.FirstName?.type === "required" &&
                `${
                  spanish
                    ? "Por favor, introduzca el nombre"
                    : "  Please enter First Name"
                }`}
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-3 p-0">
            <label htmlFor="FirstName" className=" align-middle text-end fs6 ">
              {spanish ? "Segundo nombre:" : "Middle Name:"}
            </label>
          </div>
          <div className="col-7">
            <TextField
              size="small"
              error={!!errors.MiddleName}
              {...register("MiddleName", { maxLength: 100 })}
              className=" me-3"
              label={spanish ? "Segundo nombre" : "Middle Name"}
              id="MiddleName"
              placeholder={spanish ? "Segundo nombre" : "Middle Name"}
              fullWidth
              InputLabelProps={{ style: { fontSize: "13px" } }}
              InputProps={{ readOnly: readonly, style: { fontSize: "13px" } }}
            />
          </div>
        </div>
        <div className="row p-2">
          <div className="col-3 p-0">
            <label htmlFor="FirstName" className=" align-middle text-end fs6 ">
              <span className="text-danger fw-bold fs-5"></span>{" "}
              {spanish ? "Apellido:" : "Last Name:"}
            </label>
          </div>
          <div className="col-7">
            <TextField
              size="small"
              error={!!errors.LastName}
              {...register("LastName", { required: true, maxLength: 100 })}
              className=" me-3"
              label={spanish ? "Apellido" : "Last Name"}
              id="LastName"
              placeholder={spanish ? "Apellido" : "Last Name"}
              fullWidth
              InputLabelProps={{ style: { fontSize: "13px" } }}
              InputProps={{ readOnly: readonly, style: { fontSize: "13px" } }}
            />
            <div className=" row text-danger p-auto m-auto fs5">
              {errors.LastName?.type === "required" &&
                `${
                  spanish
                    ? "Por favor, introduzca el apellido"
                    : "  Please enter Last Name"
                }`}
            </div>
          </div>
        </div>

        <div className="row p-2">
          <div className="col-3 p-0">
            <label
              htmlFor="MobileNumber"
              className=" align-middle text-end fs6 "
            >
              <span className="text-danger fw-bold fs-5"></span>{" "}
              {spanish ? "Número de móvil:" : "Mobile Number:"}
            </label>
          </div>
          <div className="col-7">
            <TextField
              size="small"
              error={!!errors.MobileNumber}
              {...register("MobileNumber", {
                required: true,
                minLength: 6,
                maxLength: 12,
              })}
              className=" me-3"
              label={spanish ? "Número de móvil" : "Mobile Number"}
              id="MobileNumber"
              placeholder={spanish ? "Número de móvil" : "Mobile Number"}
              fullWidth
              type="number"
              InputLabelProps={{ style: { fontSize: "13px" } }}
              InputProps={{ readOnly: readonly, style: { fontSize: "13px" } }}
            />
            <div className=" row text-danger p-auto m-auto fs5">
              {errors.MobileNumber?.type === "required" &&
                `${
                  spanish
                    ? "Por favor, introduzca un número de móvil válido"
                    : "Enter a valid Mobile Number"
                }`}
              {errors.MobileNumber?.type === "minLength" &&
                `${
                  spanish
                    ? "Por favor, introduzca un número de móvil válido"
                    : "Enter a valid Mobile Number"
                }`}
              {errors.MobileNumber?.type === "maxLength" &&
                `${
                  spanish
                    ? "Por favor, introduzca un número de móvil válido"
                    : "Enter a valid Mobile Number"
                }`}
            </div>
          </div>
        </div>
        <div className="row p-2">
          <div className="col-3 p-0">
            <label htmlFor="Email" className=" align-middle text-end fs6 ">
              <span className="text-danger fw-bold fs-5"></span>{" "}
              {spanish ? "Correo electrónico:" : "Email:"}
            </label>
          </div>
          <div className="col-7">
            <TextField
              size="small"
              error={!!errors.Email}
              {...register("Email", {
                required: true,
                pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              })}
              className=" me-3"
              label={spanish ? "Correo electrónico" : "Email:"}
              id="Email"
              placeholder={spanish ? "Correo electrónico" : "Email:"}
              fullWidth
              InputLabelProps={{ style: { fontSize: "13px" } }}
              InputProps={{ readOnly: readonly, style: { fontSize: "13px" } }}
            />
            <div className=" row text-danger p-auto m-auto fs5 ">
              {errors.Email?.type === "required" && "  Please enter Email"}
              {errors.Email?.type === "pattern" &&
                "  Please enter a valid Email"}
            </div>
          </div>
        </div>
        <div className=" row pt-4 ps-2 ">
          <div className="col-3"></div>
          <div className="col d-none">
            <Button htmlType="submit" type="primary">
              {spanish ? "Enviar" : "Submit"}
            </Button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default Step1;
