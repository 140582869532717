import React from "react";
function Indv({ data, tel, other }) {
  return (
    <div style={{ borderBottom: "2px solid #ffffff" }} className="row p-4">
      <div className="col text-start">{data}</div>
      <div className="col">
        {tel === "yes" ? (
          <img
            height={20}
            src="http://d9hhrg4mnvzow.cloudfront.net/go.memd.me/vpc/2160a017-icon-green-check-wide.svg"
            alt=""
          />
        ) : (
          <img
            height={20}
            src="http://d9hhrg4mnvzow.cloudfront.net/go.memd.me/vpc/5ce5be08-icon-wide-white-x-mark.svg"
            alt=""
          />
        )}
      </div>
      <div className="col">
        {other === "yes" ? (
          <img
            height={20}
            src="http://d9hhrg4mnvzow.cloudfront.net/go.memd.me/vpc/2160a017-icon-green-check-wide.svg"
            alt=""
          />
        ) : (
          <img
            height={20}
            src="http://d9hhrg4mnvzow.cloudfront.net/go.memd.me/vpc/5ce5be08-icon-wide-white-x-mark.svg"
            alt=""
          />
        )}
      </div>
    </div>
  );
}
function Comparemain({ data }) {
  return (
    <div className="bghex py-5">
      <div className="h1">
        <div className=" h1 fw-light">{data && data.content.name}</div>
        {data && data.content.description}
      </div>
      <div className="container-md rounded-4">
        <div className="row p-3">
          <div className="col"></div>
          <div className="col">TelHealth</div>
          <div className="col">Other Service Providers</div>
        </div>
        {data &&
          data.list.map((x) => {
            return (
              <Indv
                key={x.id}
                data={x.details}
                tel={x.telhealth}
                other={x.other}
              ></Indv>
            );
          })}
      </div>
    </div>
  );
}

export default Comparemain;
