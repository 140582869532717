import React, { Component } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { LBluetext, Fmenuitem } from "../../style";

export class Footert extends Component {
  render() {
    const data = this.props.data;

    return (
      <div style={{ backgroundColor: "#94a7ff" }} className="r">
        {" "}
        <div className="container pt-5 pb-4 px-4">
          <div className="row justify-content-center p-0 ">
            {data &&
              data.Menus.map((x, y) => {
                if (x.__component === "index.menu") {
                  return (
                    <div
                      key={x.id}
                      className="col  fs-5 fw-bold text-start text-light"
                    >
                      {x.menus.length === 1 ? (
                        <>
                          <h5
                            style={{ color: "#283779" }}
                            className="pb-2 lead fw-bold text-start"
                          >
                            {x.name}
                          </h5>
                          {x.menus[0].menus.map((d, e) => {
                            return (
                              <NavLink key={d.id} to={d.URL}>
                                <Fmenuitem>{d.Name}</Fmenuitem>
                              </NavLink>
                            );
                          })}
                        </>
                      ) : (
                        <>
                          <h4
                            style={{ color: "#283779" }}
                            className="pb-2  fw-bold text-start"
                          >
                            {x.name}
                          </h4>
                          {x.menus.map((a) => {
                            return (
                              <div key={a.id} className="pb-4">
                                <h6
                                  style={{ color: "#283779" }}
                                  className="pb-2  fw-bold text-start"
                                >
                                  {a.name}
                                </h6>
                                {a.menus.map((c) => {
                                  return (
                                    <NavLink key={c.id} to={c.URL}>
                                      <Fmenuitem>{c.Name}</Fmenuitem>
                                    </NavLink>
                                  );
                                })}
                              </div>
                            );
                          })}
                        </>
                      )}
                    </div>
                  );
                } else {
                  return (
                    <NavLink
                      key={x.id}
                      style={{ color: "#283779" }}
                      className="pb-2  fw-bold text-start"
                      to={x.URL}
                    >
                      {x.Name}
                    </NavLink>
                  );
                }
              })}
          </div>

          <div className=" my-4 mb-0 fs-5 fw-bold text-start pb-5 text-light">
            <div className="row ">
              <div className="text-light text-start">
                <i className="bi bi-google text-dark fs-1 p-3 ps-0  rounded-circle"></i>
                <i className="bi bi-facebook text-dark fs-1 p-3"></i>
                <i className="bi bi-twitter text-dark fs-1 p-3"></i>
              </div>
              <div></div>
            </div>
          </div>
          <div className="row  g-2 pt-3 border-top">
        
          </div>
        </div>
      </div>
    );
  }
}

export default Footert;
