import React, { Component } from "react";
import { Bigtxt, PackageButton, Shape, Smalltxt } from "./packstyle";

export class Indpackage extends Component {
  render() {
    const data = this.props.data && this.props.data.contents[0];
    function Data({ str }) {
      let count = 0;
      let there = 0;
      for (var i = 0; i < str.length; i++) {
        if (count === 2) {
          there = i;
          return (
            <Smalltxt
              dangerouslySetInnerHTML={{ __html: str.slice(0, there) }}
            ></Smalltxt>
          );
        }

        str[i] === "." ? count++ : <></>;
      }
    }

    return (
      <div
        style={{ height: 300, borderRadius: "20px" }}
        className="container p-5 position-relative bg-light shadow-lg"
      >
        <Shape>Special</Shape>
        <div className="fs-4 fw-bold py-3">{data && data.header}</div>
        <Data str={data && data.description} />

        <PackageButton className="rounded-pill my-4">Learn More</PackageButton>
      </div>
    );
  }
}

export default Indpackage;
