import React, { useState, useEffect } from "react";
import { API } from "../../../../config/urls";
import { imgconvert } from "../../../../helpers/imgconverter";
import { Blue } from "../../../style";

export default function Howitworks() {
  const [data, setdata] = useState([]);
  const Gethome = () => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: API.gethowitworks,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        setdata(response.data.data.contents);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    Gethome();
  }, []);
  function Indv({ index, h, d, i }) {
    return (index + 1) % 2 === 0 ? (
      <div className="row">
        <div className="col border-end border-2 order-1 ">
          <div className="row ">
            <div className="col ">
              <div
                style={{
                  height: 30,
                  width: 30,
                  backgroundColor: "#123",
                  color: "white",
                }}
                className="rounded-circle pt-1 my-3 ms-auto fw-bold d-block "
              >
                {index + 1}
              </div>
              <Blue className="fs-5 my-3 fw-bolder text-end d-block ">{h}</Blue>
              <div
                className=" fs-6  text-end"
                dangerouslySetInnerHTML={{ __html: d }}
              ></div>
            </div>
          </div>
        </div>

        <div className="col-sm p-3 pb-0 order-sm-2">
          <div className="ms-0 d-block">
            <img
              className="ms-0 d-block"
              style={{ marginTop: 10 }}
              height={200}
              src={imgconvert(i)}
            />
          </div>
        </div>
      </div>
    ) : (
      <div className="row ">
        <div className="col-sm border-end border-2 ">
          {" "}
          <div>
            <img className="d-block ms-auto" height={200} src={imgconvert(i)} />
          </div>
        </div>

        <div className="col-sm p-3 pt-0">
          <div className="row">
            <div className="col">
              <div>
                <div
                  style={{
                    height: 30,
                    width: 30,
                    backgroundColor: "#123",
                    color: "white",
                  }}
                  className="rounded-circle pt-1 mb-3 me-auto fw-bold d-block "
                >
                  {index + 1}
                </div>
                <Blue className="fs-5 fw-bolder text-start d-block my-2">
                  {h}
                </Blue>
              </div>
              <div
                className="text-start fs-6 "
                dangerouslySetInnerHTML={{ __html: d }}
              ></div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  return (
    <div className="py-5">
      <div>
        <div style={{}} className="container ">
          <Blue className="fs-1 fw-bolder mb-4 d-block">How it works?</Blue>
          {data &&
            data.map((x, y) => {
              return (
                <Indv
                  key={x.id}
                  index={y}
                  h={x.header}
                  d={x.description}
                  i={x.img[0].img.url}
                />
              );
            })}
        </div>
      </div>
    </div>
  );
}
