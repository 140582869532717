import React from "react";
import { convertlr } from "../../../../helpers/covertlr.ts";
import { imgconvert } from "../../../../helpers/imgconverter";
import "../style.jsx";
import { Fbutton } from "../style.jsx";

const Affordable = ({ data }) => {
  return (
    <div className="container py-5">
      <div className="row align-items-center">
        <div
          className={`col ${
            convertlr(data && data.content.img[0] && data.content.img[0].pos)
              ? "order-1"
              : ""
          }`}
        >
          <div className="row align-items-center g-4 ">
            {data &&
              data.content.img.map((x) => {
                return (
                  <div key={x.id} className="col">
                    <img height={60} src={imgconvert(x.img.url)} alt="" />
                  </div>
                );
              })}
          </div>
        </div>
        <div className="col-8 m-auto">
          <div className="row ">
            <h1 className="fw-bolder p-4">{data && data.content.name}</h1>
            <h4
              className="px-5 py-3 text-start fw-light"
              dangerouslySetInnerHTML={{
                __html: data && data.content.description,
              }}
            >
              {}
            </h4>
          </div>
          <div className="row justify-content-center">
            <Fbutton className="rounded-pill w-50">
              {data && data.button}
            </Fbutton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Affordable;
