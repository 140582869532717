import { Row, Skeleton } from "antd";
import { useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import "./App.css";
import { API } from "./config/urls";
import { imgconvert } from "./helpers/imgconverter";
import Footert from "./pages/Local/Footer/Footer";
import Navb from "./pages/Local/Nav/Nav";
import "./pages/style.jsx";

function App() {
  const [bgcolor, setbgcolor] = useState("");
  const [navdata, setnavdata] = useState();
  const [footerdata, setfooterdata] = useState();
  const [logo, setlogo] = useState();
  const Getnav = () => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: API.getheader,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        setnavdata(response.data.data.Menus);
        setlogo(response.data.data.logo);
      })

      .catch(function (error) {
        console.log(error);
      });
  };
  const Getfooter = () => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: API.getfooter,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        setfooterdata(response.data.data);
      })

      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    Getnav();
    Getfooter();
    const onScroll = () => {
      window.pageYOffset > 60 ? setbgcolor("blurnav") : setbgcolor("");
    };

    window.removeEventListener("scroll", onScroll);
    window.addEventListener("scroll", onScroll, { passive: true });
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="App">
      <div className="">
        <div style={{ zIndex: 1 }} className=" position-fixed top-0 w-100">
          <div className={bgcolor}>
            <Navb data={navdata} logo={imgconvert(logo && logo.url)} />
          </div>
        </div>
      </div>

      <Outlet />
      <div className="bg-light">
        <Footert data={footerdata} />
      </div>
    </div>
  );
}

export default App;
