import { Button, Result } from "antd";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Stripepage } from "./payment/main";
import Renewpage from "./renewpage";

function Mainexpired({ spanish }) {
  const navigate = useNavigate();
  const [paymode, setpaymode] = useState(false);
  return (
    <div>
      {paymode ? (
        <>
          <Renewpage spanish={spanish} />
        </>
      ) : (
        <div
          style={{ maxWidth: 500 }}
          className="bg-white container rounded-4 shadow-lg"
        >
          <div className="m-auto">
            <Result
              className=""
              status="403"
              subTitle={
                <div>
                  <div className="lead py-2 text-dark ">
                    {spanish
                      ? "Su membresía ha expirado."
                      : "Your Membership has Expired."}
                  </div>
                  <div className="lead fs-6">
                    {spanish
                      ? `Renueve o Comuníquese con su Proveedor de Servicios.`
                      : `Please Renew Or Contact your service provide.`}
                  </div>
                </div>
              }
              extra={
                <>
                  <Button
                    type="primary"
                    onClick={() => {
                      setpaymode(true);
                    }}
                    className=" btn rounded-pill shadow-lg  "
                  >
                    {spanish
                      ? "Renueve su suscripción"
                      : "Renew Your Subscription"}
                  </Button>
                </>
              }
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default Mainexpired;
