import React from "react";
import ReactDOM from "react-dom/client";
import Webroutes from "./pages/routes";
import "antd/dist/antd.min.css";
import { HelmetProvider } from "react-helmet-async";
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HelmetProvider>
    <Webroutes />
  </HelmetProvider>
);
