const URL = process.env.REACT_APP_APPURLMAIN;
const URLs = URL + "/api/";
const addon = "?populate=deep,10";
const API = {
  gethome: URLs + "index" + addon,
  gethowitworks: URLs + "how-it-work" + addon,
  getheader: URLs + "header" + addon,
  getfooter: URLs + "footer" + addon,
  getpackages: URLs + "packages" + addon,
  getabout: URLs + "about" + addon,
  getour_providers: URLs + "our-provider" + addon,
  getwhy_us: URLs + "why-us" + addon,
  getvirtual: URLs + "virtualprimarycare" + addon,
  getwhat_we_treat: URLs + "what-we-do" + addon,
  getwho_we_serve: URLs + "who-we-serve" + addon,
  getservices: URLs + "service" + addon,
  getcontact: URLs + "contact-us" + addon,
  getfaqs: URLs + "faqs" + addon,
  getreviews: URLs + "reviews" + addon,
  getoffice_signup: URLs + "office-signup" + addon,
  checkemail: URLs + "external/email_checker",
  addformdata: URLs + "form-datas/",
  getpricing: URLs + "pricing" + addon,
  getallprices: URLs + "external/getpackages/",
  getindvprice: URLs + "external/getindvpackages/",
};

export { API, URL, URLs };
