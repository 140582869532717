import React from "react";
import { Spin } from "antd";
import { useEffect, useState } from "react";
import Faqcomponent from "../faqs/faqcomponent";
import Joinus from "../Local/components/joinus";
import Messaging from "../Local/components/messaging";
import Affordable from "./Components/affordable/affordable";
import Howitworks from "./Components/howitworks/index";
import Messageslider from "./Components/messageslider/messageslider";
import Productsbody from "./Components/products/products";
import Hometop from "./Components/top/top";
import { API } from "../../config/urls";
import { convertlr } from "../../helpers/covertlr.ts";
import { imgconvert } from "../../helpers/imgconverter";
import SEO from "../../helpers/seo";

function Home() {
  const [maindata, setmaindata] = useState({});
  const [mainprocessing, setmainprocessing] = useState(false);
  const Gethome = () => {
    var axios = require("axios");
    var config = {
      method: "get",
      url: API.gethome,
      headers: {},
    };
    axios(config)
      .then(function (response) {
        console.log(response);
        setmaindata(response.data.data);
      })
      .catch(function (error) {
        console.log(error);
      });
  };
  useEffect(() => {
    console.log("Adawd")
    Gethome();
  }, []);

  if (mainprocessing) {
    return (
      <div style={{ height: 500, paddingTop: 200 }} className="">
        <Spin />
      </div>
    );
  }
  return (
    <div>
      <SEO
        title={maindata && maindata.seo && maindata.seo.title}
        description={maindata && maindata.seo && maindata.seo.description}
        image={imgconvert(
          maindata && maindata.seo && maindata.seo.social_media_card_img.url
        )}
        name={maindata && maindata.seo && maindata.seo.name}
        type={maindata && maindata.seo && maindata.type}
      />
      <Hometop data={maindata.first} diseases={maindata && maindata.Diseases} />
      <div className="position-relative" style={{ backgroundColor: "" }}>
        <Messaging data={maindata.second} />
        <Joinus />
        <Howitworks />
        <Productsbody data={maindata.Third} />
        <Affordable data={maindata.fourth} />
        <div className="container">
          <Messageslider data={maindata.review} />
        </div>
      </div>
      <div className="container-md ">
        <div className="row ">
          <div
            className={`col-sm  col-lg-8 bg-white rounded-5 ${
              convertlr(maindata.faqs && maindata.faqs.img.pos) ? "" : "order-1"
            }`}
          >
            <Faqcomponent />
          </div>
          <div className="col-sm d-sm-block d-none">
            <img
              height={500}
              src={imgconvert(maindata.faqs && maindata.faqs.img.img.url)}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Home;
