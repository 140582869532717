import styled from "styled-components"

export const Fbutton = styled.button`
border:1px solid #283779;
padding:10px;
border-raduis:50%;
background-color:white;
&:hover {
    background-color:#283779 ;
    color:white;
    
  }

`
