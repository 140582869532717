import { Space, Table, Tag } from "antd";
import React, { useState } from "react";
import useId from "../../../useId";
import Spanishname from "../spanishname";

const History = ({ spanish }) => {
  const { Id, setId } = useId();
  const [data2, setdata2] = useState(Id.membership.membership_history);
  const columns = [
    {
      title: spanish ? "Nombre del paquete" : "Package Name",

      key: "id",
      render: (text, record) => (
        <div className="ms-2">
          {spanish ? (
            <Spanishname e={record.package.plan} />
          ) : (
            record.package.plan
          )}
        </div>
      ),
    },
    {
      title: spanish ? "Fecha de inicio del beneficio" : "Benefit Start Date",
      dataIndex: "benefit_start",
      key: "benefit_start",
    },
    {
      title: spanish
        ? "Fecha de finalización del beneficio"
        : "Benefit End Date",
      dataIndex: "benefit_end",
      key: "benefit_end",
    },
    {
      title: spanish ? "Términos" : "Terms",
      dataIndex: "terms",
      key: "terms",
      render: (text) => (
        <>
          {text === "Yearly"
            ? `${spanish ? "Anual" : "Yearly"}`
            : `${spanish ? "Mensual" : "Monthly"}`}
        </>
      ),
    },
    {
      title: spanish ? "Precio" : "Price",
      dataIndex: "calculated_price",
      key: "calculated_price",
      render: (text) => <>${text}</>,
    },
    {
      title: spanish ? "Estado" : "Status",
      key: "expired",
      dataIndex: "expired",
      render: (text) => (
        <>
          <Tag color={text ? "error" : "success"}>
            {text
              ? `${spanish ? "Caducado" : "Expired"}`
              : `${spanish ? "Activo" : "Active"}`}
          </Tag>
        </>
      ),
    },
  ];

  return (
    <div className="">
      <div className="container-lg p-0">
        <Table className="" columns={columns} dataSource={data2} />
      </div>
    </div>
  );
};

export default History;
