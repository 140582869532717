import { notification } from "antd";
import axios from "axios";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import { API } from "../../config/urls";
import { imgconvert } from "../../helpers/imgconverter";

export default function Offisignup() {
  const navigate = useNavigate();
  const [step, setstep] = useState(0);
  const [mapdata, setmapdata] = useState();
  const [data, setdata] = useState({
    type: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const openNotification = () => {
    notification.open({
      message: "Thank you for getting in touch!",

      description:
        "We appreciate you contacting us. One of our team member will get back in touch with you soon! Have a great day!",
    });
  };
  useEffect(() => {
    axios(API.getoffice_signup).then((x) => {
      setmapdata(x.data.data.reseller_types);
    });
  }, []);
  const onSubmit = (x) => {
    axios
      .post(API.addformdata, { data: { ...x, Type: data.type } })
      .then((x) => {
        openNotification();
        navigate("/");
      });
  };
  return (
    <>
      <div style={{}}>
        <div className="row ">
          <div
            className="col-md-8 "
            style={{ backgroundColor: "", paddingTop: 170 }}
          >
            {step === 0 ? (
              <div className="">
                <h3 className="fw-bold p-4">Tell Us what's you Situation?</h3>
                {mapdata &&
                  mapdata.map((x, y) => {
                    return (
                      <div className="">
                        <label
                          style={{}}
                          role="button"
                          htmlFor={y}
                          name="select"
                          className={"d-block pe-auto "}
                        >
                          <div
                            style={{
                              maxWidth: 500,
                              height: 70,
                              margin: "auto",
                            }}
                            className="rounded-4 shadow mb-2  bg-white p-2 position-relative"
                          >
                            <span style={{}} className="float-start">
                              <img
                                style={{ objectFit: "cover" }}
                                className="rounded-circle"
                                height={50}
                                width={50}
                                src={imgconvert(x.img.url)}
                                alt=""
                              />
                            </span>
                            <span
                              style={{ top: 22, left: 120 }}
                              className="position-absolute fw-bold"
                            >
                              {x.name}{" "}
                            </span>
                            <span
                              style={{ top: 18, right: 20 }}
                              className="position-absolute"
                            >
                              <i class="bi bi-chevron-right fs-4 "></i>
                            </span>
                          </div>
                        </label>
                        <input
                          id={y}
                          type="radio"
                          name="select"
                          value={x.type}
                          className={"d-none"}
                          onChange={(x) => {
                            setdata({ type: x.target.value });
                            setstep(2);
                          }}
                        />
                      </div>
                    );
                  })}
                <div className="p-5">
                  <Link to="/" className="text-decoration-underline text-black">
                    <i class="bi bi-arrow-left p-1"></i>
                    {"   "}Back
                  </Link>
                </div>
              </div>
            ) : (
              <>
                <div
                  style={{ maxWidth: 700, margin: "auto" }}
                  className="col container shadow py-2 my-3 m-auto rounded-5 p-5"
                >
                  <h3 className="fw-bold p-4">Tell Us More About yourself?</h3>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row ">
                      <div className="col">
                        <div className="row w-100">
                          <label className="py-3" htmlFor="fname">
                            First Name:
                          </label>
                          <input
                            className="checkoutinput"
                            type="text"
                            placeholder="First name"
                            id="fname"
                            {...register("first_name", {
                              required: true,
                              maxLength: 80,
                            })}
                          />
                        </div>
                        <div className=" row text-danger  ">
                          {errors.first_name?.type === "required" &&
                            "  Please enter First Name"}
                        </div>
                      </div>

                      <div className="col">
                        <div className="row w-100">
                          <label className="py-3" htmlFor="lname">
                            Last Name:
                          </label>
                          <input
                            className="checkoutinput"
                            id="lname"
                            type="text"
                            placeholder="Last name"
                            {...register("last_name", {
                              required: true,
                              maxLength: 100,
                            })}
                          />
                        </div>

                        <div className=" row text-danger  d-inline ">
                          {errors.last_name?.type === "required" &&
                            "  Please enter Last Name"}
                        </div>
                      </div>
                    </div>
                    <div className="row ">
                      <div className="col">
                        <div className="row w-100">
                          <label className="py-3" htmlFor="mo">
                            Mobile Number:
                          </label>
                          <input
                            className="checkoutinput"
                            type="text"
                            placeholder="Mobile Number"
                            id="mo"
                            {...register("mobile_number", {
                              maxLength: 12,
                            })}
                          />
                        </div>
                        <div className=" row text-danger  ">
                          {errors.mobile_number?.type === "maxLength" &&
                            "  Please enter a valid Mobile Number"}
                        </div>
                      </div>

                      <div className="col">
                        <div className="row w-100">
                          <label className="py-3" htmlFor="em">
                            Email:
                          </label>
                          <input
                            className="checkoutinput"
                            id="em"
                            type="text"
                            placeholder="Email"
                            {...register("email", {
                              required: true,
                              maxLength: 100,
                              pattern:
                                /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
                            })}
                          />
                        </div>

                        <div className=" row text-danger  d-inline ">
                          {errors.email?.type === "required" &&
                            "  Please enter  Email"}
                          {errors.email?.type === "pattern" &&
                            "  Please enter a valid  Email"}
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <label
                        htmlFor="exampleFormControlTextarea1"
                        className="text-start p-2"
                      >
                        <span className="text-start">Message:</span>
                      </label>
                      <textarea
                        className="form-control text-start"
                        id="exampleFormControlTextarea1"
                        rows={3}
                        defaultValue={""}
                        {...register("message")}
                      />
                    </div>
                    <div className="container  py-2">
                      <input
                        className="checkoutinput"
                        type="submit"
                        value="Submit"
                      />
                    </div>
                  </form>

                  <div className="container">
                    <div className="pe-4 pt-3">
                      <div
                        role="button"
                        onClick={() => {
                          setstep(0);
                        }}
                        className="text-decoration-underline  text-black"
                      >
                        <i class="bi bi-arrow-left "></i>
                        {"   "}Back
                      </div>
                    </div>
                  </div>
                </div>
              </>
            )}
          </div>
          <div style={{ paddingTop: 140 }} className="col overflow-hidden">
            <img
              height={500}
              className={""}
              src="https://img.freepik.com/free-vector/sign-up-concept-illustration_114360-7895.jpg?w=2000"
              alt=""
            />
          </div>
        </div>
      </div>
    </>
  );
}
