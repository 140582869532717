import React from "react";

function Ploveline({ data }) {
  return (
    <div className="row bg-light p-3 py-5 g-3 m-0">
      <div className="col-2 col-sm-0"></div>
      <div className="col-md-3 order-md-1 ">
        <div className="row g-2">
          {data &&
            data.detail.map((x) => {
              return (
                <div className="col" key={x.id}>
                  <div>
                    <h1 className="fw-bolder">{x.number}%</h1>
                  </div>
                  <div style={{ fontSize: 12 }}>{x.description}</div>
                </div>
              );
            })}
        </div>
      </div>
      <div className="col order-md-2">
        <div className="mb-4">
          <span
            className="h1 bold p-1 m-2  fw-bold "
            style={{ borderBottom: "2px solid black" }}
          >
            {data && data.header}
          </span>
        </div>
        <div
          style={{ fontSize: 14, maxWidth: 350 }}
          className="lead m-auto"
          dangerouslySetInnerHTML={{ __html: data && data.description }}
        ></div>
      </div>
    </div>
  );
}

export default Ploveline;
