import { Layout, Menu } from "antd";

import { Route, Routes, Link } from "react-router-dom";
import React from "react";
import Basic from "./settingstype/basic";
import Advanced from "./settingstype/advanced";
const { Content, Sider } = Layout;

const MemberSettingsmain = ({ spanish }) => (
  <Layout className="p-4">
    <Content style={{}}>
      <Layout className="site-layout-background py-3" style={{}}>
        <Sider
          style={{ position: "relative" }}
          className="site-layout-background"
          width={200}
        >
          <Menu defaultSelectedKeys={"awd"}>
            <Menu.Item key={"awd"}>
              <Link to={"./basic"}>
                {spanish ? "Ajustes básicos" : "Basic Settings"}
              </Link>
            </Menu.Item>
            <Menu.Item key={"awdwad"}>
              <Link to={"./advanced"}>
                {spanish ? "Ajustes avanzados" : "Advanced Settings"}
              </Link>
            </Menu.Item>
          </Menu>
        </Sider>
        <Content
          style={{
            padding: "0 24px",
            minHeight: 280,
          }}
        >
          <Routes>
            <Route path="basic" element={<Basic spanish={spanish} />} />
            <Route path="advanced" element={<Advanced spanish={spanish} />} />
          </Routes>
        </Content>
      </Layout>
    </Content>
  </Layout>
);

export default MemberSettingsmain;
