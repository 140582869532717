import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { API } from "../../config/urls";
import { convertlr } from "../../helpers/covertlr.ts";
import { imgconvert } from "../../helpers/imgconverter";
import SEO from "../../helpers/seo";
import Faqcomponent from "../faqs/faqcomponent";
import Howitworks from "../Home/Components/howitworks";
import Joinus from "../Local/components/joinus";
import Indvservices from "./components/indvservices";
import Parallex from "./components/parallex";

import Pricingbase from "./components/pricing";

function Indvbundle({ data }) {
  const navigate = useNavigate();
  const [price, setprice] = useState();
  data &&
    data.packagedetails &&
    axios
      .get(`${API.getindvprice}${data.packagedetails.revmd_package__id}`)
      .then((x) => {
        setprice(x.data.price);
      });
  function Diseasebox({ data }) {
    const [on, seton] = useState(false);
    return (
      <div
        style={{ transition: " all .3s ease-in-out" }}
        onMouseEnter={() => {
          seton(true);
        }}
        onMouseLeave={() => {
          seton(false);
        }}
        className="col-sm m-2 p-0 rounded-4 bg-white shadow p-0 "
      >
        {on ? (
          <div style={{ height: 136, paddingTop: 60 }}>
            <button
              onClick={() => {
                window.scroll(0, 1200);
              }}
              className="btn homebtn m-auto p-auto vertical-align-middle d-block"
            >
              Learn More
            </button>
          </div>
        ) : (
          <div>
            <img
              style={{
                borderTopRightRadius: 13,
                borderTopLeftRadius: 13,
                borderBottomRadius: 0,
                objectFit: "cover",
              }}
              width={"100%"}
              height={120}
              src={imgconvert(data.img.url)}
              alt=""
            />
            <div style={{ fontSize: 14, fontWeight: 500 }} className=" py-2 ">
              <span className="float-start ps-3 p-2">{data.name}</span>
              <span>
                <i className="bi bi-arrow-right float-end pe-3 p-2"></i>
              </span>
            </div>
          </div>
        )}
      </div>
    );
  }
  return (
    <div>
      <SEO
        title={data && data.seo && data.seo.title}
        description={data && data.seo && data.seo.description}
        image={data && data.seo && data.seo.social_media_card_img}
        name={data && data.seo && data.seo.name}
        type={data && data.seo && data.type}
      />
      <div>
        {" "}
        <div className="position-relative bghex py-3" style={{}}>
          <div className="container p-0">
            <div style={{ paddingTop: 80 }} className="row ">
              <div
                className={`col-xl-5  ${
                  convertlr(
                    data &&
                      data.First_Part &&
                      data.First_Part.content.img[0].pos
                  )
                    ? "order-1"
                    : ""
                }`}
              >
                <img
                  height={500}
                  className="m-auto  d-none d-md-block"
                  src={imgconvert(
                    data &&
                      data.First_Part &&
                      data.First_Part.content.img[0].img.url
                  )}
                  alt=""
                />
              </div>
              <div className="col order-1">
                <div className="container">
                  <div
                    style={{ fontSize: 50 }}
                    className="h1 fw-bold text-start "
                  >
                    {data && data.First_Part.content.header}
                  </div>
                  <div
                    className="label text-start pt-3 w-75"
                    dangerouslySetInnerHTML={{
                      __html: data && data.First_Part.content.description,
                    }}
                  ></div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="homebtn shadow-lg fs-3 fw-bold w-50 my-4"
                    onClick={() => {
                      navigate("/packages");
                    }}
                  >
                    Start Visit
                  </div>
                  <div className="h5 py-4 text-start">What treats ?</div>

                  <div className="row pt-4 g-4">
                    {data &&
                      data.diseases.menus[0].diseases.map((x) => {
                        return <Diseasebox data={x} />;
                      })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ maxWidth: 700 }} className="container my-5">
          <div className="m-3 ">
            <span className="lead fw-bold fs-4 border-2 border-bottom p-3 m-5">
              {data.Secondpart.menu.header}
            </span>
          </div>
          <div className="d-none row mb-5 mt-5">
            {data.Secondpart.menu.menus.map((x) => {
              return (
                <div key={x.id} className={"col"}>
                  <div
                    className="rounded-circle p-3 my-3"
                    style={{
                      height: 100,
                      width: 100,
                      margin: "auto",
                      backgroundColor: "#8c97d3",
                    }}
                  >
                    <img
                      className=""
                      src={imgconvert(x.contents[0].img[0].img.url)}
                      height={100}
                      alt=""
                    />
                  </div>
                  <div>{x.contents[0].description}</div>
                </div>
              );
            })}
          </div>
        </div>
        <Pricingbase
          price={price}
          data={data.Pricing}
          name={data.packagedetails.Main_Package_Name}
        />
        {data &&
          data.Services &&
          data.Services.map((x, y) => {
            return (
              <Indvservices
                key={x.id}
                data={x}
                flip={y % 2 === 0 ? true : false}
              />
            );
          })}
        <Howitworks />
      </div>
      <div>
        <Parallex
          left={<Faqcomponent />}
          right={
            <h1
              style={{ fontSize: 60, verticalAlign: "middle" }}
              className="p-3 rounded-5 my-auto shadow-lg fw-bold"
            >
              {data.header} FAQs
            </h1>
          }
        />
      </div>
      <div>
        <Joinus />
      </div>
    </div>
  );
}

export default Indvbundle;
