import React from "react";
import { URL } from "../../../config/urls";
import { convertlr } from "../../../helpers/covertlr.ts";
import { Blue } from "../../style";

function Messaging({ data }) {
  function Text(props) {
    return (
      <div
        style={{
          width: 250,
          height: 100,

          margin: `${props.margin}`,
        }}
        className="position-relative rounded-4 "
      >
        <div
          style={{
            backgroundColor: "#dfefff",
            fontSize: 12,
            borderRadius: 20,
            borderEndEndRadius: props.left ? 20 : 0,
            borderEndStartRadius: props.left ? 0 : 20,
          }}
          className=" position-absolute p-3 bottom-0"
        >
          {props.msg}
        </div>
        <div
          className="position-absolute rounded-circle p-1 "
          style={{
            height: 30,
            width: 30,
            right: props.left ? null : -35,
            left: props.left ? -35 : null,
            bottom: 0,
            backgroundColor: "#283779",
            color: "white",
          }}
        >
          Js
        </div>
      </div>
    );
  }
  return (
    <div className="py-5 px-2 container">
      <div className="row">
        <div
          className={`col py-5 px-3 ${
            convertlr(data && data.content.img && data.content.img.pos)
              ? ""
              : "order-4"
          }`}
        >
          <Blue style={{ fontWeight: 700 }} className="h2 text-start d-block">
            {data && data.content.header}
          </Blue>
          <div className="text-start lead my-4 fw-bold">
            {data && data.content.sub_header}
          </div>
          <div
            className="text-start"
            dangerouslySetInnerHTML={{
              __html: data && data.content.description,
            }}
          ></div>
          <div className="ms-0">
            <span className="homebtn btn rounded-5 p-2 px-4 my-4 ms-0">
              Learn What we Offer
            </span>
          </div>
        </div>
        <div className="col-md position-relative order-md-2">
          <div className="px-5">
            <Text
              left={true}
              margin="auto"
              msg="Hi again Mariana. How can I help you?"
            />
            <div className="ms-0">
              {" "}
              <Text
                margin="0 0 0 auto"
                msg="I have a question about my medication"
              />
            </div>

            <Text
              left={true}
              margin="auto"
              msg="Sure. First, let me ask you a few things about how you're managing your condition."
            />
            <img
              className="d-block"
              style={{ maxWidth: 500, margin: "-20px 0 0 60px" }}
              width="90%"
              src={`${URL}${
                data && data.content.img[0] && data.content.img[0].img.url
              }`}
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Messaging;
