import React, { useEffect, useState } from "react";
import { convertlr } from "../../../../helpers/covertlr.ts";
import { imgconvert } from "../../../../helpers/imgconverter";
const Productsbody = ({ data }) => {
  const [selected, setselected] = useState(data && data.contents[0]);
  useEffect(() => {
    setselected(data && data.contents[0]);
  }, [data]);
  return (
    <div className="container p-4 rounded-4">
      <div
        style={{ backgroundColor: "#b2dded" }}
        className="card border-0 shadow-lg text-center rounded-5 "
      >
        <div
          style={{ backgroundColor: "#283779" }}
          className="card-header shadow-lg border-0 rounded-5"
        >
          <ul className="nav nav-pills card-header-pills mx-3">
            {data &&
              data.contents.map((x) => {
                return (
                  <li
                    key={x.id}
                    style={{ backgroundColor: "#b2dded", color: "white" }}
                    className="text-light shadow rounded-pill m-2"
                  >
                    <a
                      onClick={() => {
                        setselected(x);
                      }}
                      className="nav-link fw-bold "
                    >
                      {x.name}
                    </a>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="card-body">
          <div>
            <div className="row justify-content-center">
              <div
                className={`col-md p-5  ${
                  convertlr(selected && selected.img[0].pos) ? "" : "order-1"
                }`}
              >
                <div className="row">
                  <h2 className="fw-bolder p-2">{selected && selected.name}</h2>
                  <h6>{selected && selected.description}</h6>
                  <a className="d-block" href="#">
                    Learn more ›
                  </a>
                </div>
              </div>
              <div style={{ alignSelf: "center" }} className="col ">
                <img
                  className="w-25"
                  src={imgconvert(selected && selected.img[0].img.url)}
                  alt=""
                />{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Productsbody;
