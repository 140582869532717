import React, { useState } from "react";
import { loadStripe } from "@stripe/stripe-js";
import {
  CardElement,
  Elements,
  useStripe,
  useElements,
} from "@stripe/react-stripe-js";
import "./style.css";
import { message } from "antd";

import useId from "../../../../useId";
import useToken from "../../../../useToken";
import moment from "moment";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#4a4a4a",
      color: "#4a4a4a",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#4a4a4a",
      },
      "::placeholder": {
        color: "#4a4a4a",
      },
    },
    invalid: {
      iconColor: "#ff3737",
      color: "#ff3737",
    },
  },
};

const CardField = ({ onChange }) => (
  <div className="FormRow">
    <CardElement options={CARD_OPTIONS} onChange={onChange} />
  </div>
);

const Field = ({
  label,
  id,
  type,
  placeholder,
  required,
  autoComplete,
  value,
  onChange,
}) => (
  <div className="FormRow">
    <input
      className="FormRowInput"
      id={id}
      type={type}
      placeholder={placeholder}
      required={required}
      autoComplete={autoComplete}
      value={value}
      onChange={onChange}
    />
  </div>
);

const SubmitButton = ({ processing, error, children, disabled, spanish }) => (
  <button
    style={{ backgroundColor: "#002140" }}
    className={`SubmitButton rounded-4 ${error ? "SubmitButton--error" : ""}`}
    type="submit"
    disabled={processing || disabled}
  >
    {processing ? (spanish ? "Procesando..." : "Processing...") : children}
  </button>
);

const ErrorMessage = ({ children }) => (
  <div className="ErrorMessage" role="alert">
    {children}
  </div>
);

const CheckoutForm = (props) => {
  const stripe = useStripe();
  const spanish = props.spanish;
  const elements = useElements();
  const [error, setError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const { Id, setId } = useId();
  const { token, setToken } = useToken();
  const [billingDetails, setBillingDetails] = useState({
    email: "",
    name: "",
    Address: "",
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    const postdata = (propsdata) => {
      if (propsdata.error) {
        setProcessing(false);
        setError(propsdata.error);
        return;
      }
      const axios = require("axios");
      let data = JSON.stringify({
        email: Id.email,
        first_name: Id.first_name,
        middle_name: Id.middle_name,
        last_name: Id.last_name,
        gender: Id.gender,
        date_of_birth: moment(Id.date_of_birth).format("MM-DD-YYYY"),
        address_1: Id.address_1,
        address_2: Id.address_2,
        zip: Id.zip,
        city: Id.city,
        state: Id.state,
        language: Id.language,
        phone_number: Id.phone_number,
        package: props.package,
        recurring: props.recurring,
        terms: props.terms,
        language: Id.language,
        card_source: propsdata.token.id,
      });

      let config = {
        method: "put",
        url:
          process.env.REACT_APP_EXTERNAl_APPURL +
          "v1/" +
          Id.membership.office +
          "/memberships/" +
          Id.id +
          "/",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",

          Authorization: "Token" + " " + `${token}`,
        },
        data: data,
      };

      axios(config)
        .then((response) => {
          message.success(
            spanish ? "Suscripción renovada" : "Subscription Renewed"
          );
          window.location.replace("/dashboard");
        })
        .catch((error) => {
          setProcessing(false);
          function ifcarderror() {
            const x = error.response.data[0];
            let y = x.search(":");
            message.error(x.slice(y + 1));
          }
          function unactive() {
            message.error("There was an error.Please try agian later or Contact the service provider.");
          }
          typeof error.response.data[0] === `string`
            ? ifcarderror()
            : unactive();
        });
    };
    if (!stripe || !elements) {
      // Stripe.js has not loaded yet. Make sure to disable
      // form submission until Stripe.js has loaded.
      return;
    }

    const card = elements.getElement(CardElement);

    if (card == null) {
      return;
    }

    if (error) {
      card.focus();
      return;
    }

    if (cardComplete) {
    }

    const cardElement1 = elements.getElement("card");

    stripe.createToken(cardElement1).then((payload) => {
      setProcessing(true);
      postdata(payload);
    });
  };

  return (
    <form className="Form" onSubmit={handleSubmit}>
      <fieldset className="FormGroup">
        <div
          style={{ fontWeight: 600, fontSize: "14px" }}
          className="fs-14 pb-2  opacity-75"
        >
          {spanish ? "Nombre en la Tarjeta" : "Name on the card"}
        </div>
        <Field
          label="Name"
          id="name"
          type="text"
          placeholder={spanish ? "Nombre" : "Name"}
          required
          autoComplete="name"
          value={billingDetails.name}
          onChange={(e) => {
            setBillingDetails({ ...billingDetails, name: e.target.value });
          }}
        />
      </fieldset>
      <fieldset className="FormGroup pt-2 py-0">
        <CardField
          onChange={(e) => {
            setError(e.error);
            setCardComplete(e.complete);
          }}
        />
        <div>{error && <ErrorMessage>{error.message}</ErrorMessage>}</div>
      </fieldset>

      <SubmitButton
        spanish={spanish}
        processing={processing}
        error={error}
        disabled={!stripe}
      >
        {spanish ? "Pague" : "Pay"} ${props.payprice}
      </SubmitButton>
    </form>
  );
};

const ELEMENTS_OPTIONS = {};

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.

export const Stripepage = (props) => {
  const stripePromise = loadStripe(props.publishkey);
  return (
    <div className="AppWrapper">
      <Elements stripe={stripePromise} options={ELEMENTS_OPTIONS}>
        <CheckoutForm
          spanish={props.spanish}
          recurring={props.recurring}
          package={props.package}
          terms={props.terms}
          payprice={props.price}
        />
      </Elements>
    </div>
  );
};
