import { Collapse } from "antd";
import axios from "axios";
import React, { useState } from "react";
import { useEffect } from "react";
import { API } from "../../config/urls";
const { Panel } = Collapse;

function Faqcomponent() {
  const [data, setdata] = useState();
  useEffect(() => {
    axios(API.getfaqs).then((x) => {
      setdata(x.data.data);
    });
  }, []);
  return (
    <div className="position-relative py-4">
      <div className="lead">Questions</div>
      <div className="h1 fw-bold">Frequently Asked Questions</div>
      <div className="container my-5">
        <Collapse className="bg-white border-0 " accordion>
          {data &&
            data.map((x) => {
              return (
                <Panel
                  className="p-0 m-3 rounded-4 bg-white shadow-lg"
                  header={<div className="lead">{x.title}</div>}
                  key={x.id}
                >
                  <div dangerouslySetInnerHTML={{ __html: x.answer }}></div>
                </Panel>
              );
            })}
        </Collapse>
      </div>
    </div>
  );
}

export default Faqcomponent;
