import React from "react";
import { useContext } from "react";
import { useState } from "react";
import { Packages } from "../main";
import "./style/slider.css";
export default function Slider() {
  const [silder, setsilder] = useState(true);
  const [state, actions] = useContext(Packages);
  return (
    <div className="switch">
      {state.terms === "monthly" ? (
        <div className="button" id="button-container">
          <div id="my-button" className="button-element">
            <span id="Monthly">Monthly</span>
          </div>
          <span
            onClick={() => {
              setsilder(false);
              actions({ ...state, ...{ terms: "yearly" } });
            }}
            id="Yearly"
          >
            Yearly
          </span>
        </div>
      ) : (
        <div className="button" id="button-container">
          <span
            onClick={() => {
              setsilder(true);
              actions({ ...state, ...{ terms: "monthly" } });
            }}
            style={{ paddingRight: 12 }}
            className="ps-0"
            id="Yearly"
          >
            Monthly
          </span>{" "}
          <div id="my-button" className="button-element">
            <span id="Monthly">Yearly</span>
          </div>
        </div>
      )}

      <label hidden>
        <input
          id="for-button"
          type="text"
          name="method"
          value="Yearly"
          readOnly
        />
      </label>
    </div>
  );
}
