import axios from "axios";
import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { API } from "../../../../config/urls";
import useWindowDimensions from "../../../../hooks/windowsize";
import { Blue, Lbluebg } from "../../../style";
import "./messageslider.css";
import { Messagesliderindlg, Messagesliderindsm } from "./messsagesliderind";
const data2 = ["", "", "", ""];
const Messageslider = ({ data }) => {
  const [reviews, setreviews] = useState();
  const { width } = useWindowDimensions();
  useEffect(() => {
    axios(API.getreviews).then((x) => {
      setreviews(x.data.data);
    });
  }, []);
  function Calc(maind) {
    if (maind === undefined) return;
    function loopcount(s, e) {
      const array = [];
      for (let i = s; i < e; i++) {
        array.push(i);
      }
      return array;
    }
    const array = [];
    for (let i = 0; i < maind.length; i = i + 2) {
      {
        i == 0
          ? maind.length >= 2
            ? array.push(loopcount(0, 2))
            : array.push(loopcount(0, maind.length))
          : array.push(
              loopcount(i, maind.length >= i + 2 ? i + 2 : maind.length)
            );
      }
    }
    return array;
  }
  return (
    <div className=" rounded-4">
      <Lbluebg className="rounded-5 py-5 px-2">
        <Blue style={{ fontSize: "60px" }} className="fw-bold">
          {data && data.header}
        </Blue>
        <div
          id="messageslider"
          className="carousel slide text-dark  py-5"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            {width < 1000
              ? reviews &&
                reviews.map((x, y) => {
                  return (
                    <button
                      key={y}
                      type="button"
                      data-bs-target="#messageslider"
                      data-bs-slide-to={y}
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    />
                  );
                })
              : Calc(reviews && reviews) &&
                Calc(reviews && reviews).map((x, y) => {
                  return (
                    <button
                      key={y}
                      type="button"
                      data-bs-target="#messageslider"
                      data-bs-slide-to={y}
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    />
                  );
                })}
          </div>
          <div className="carousel-inner ">
            {width < 1000 ? (
              <Messagesliderindsm data={reviews} />
            ) : (
              <Messagesliderindlg data={reviews} array={Calc(reviews)} />
            )}
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#messageslider"
            data-bs-slide="prev"
          >
            <span className="carousel-control-prev-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#messageslider"
            data-bs-slide="next"
          >
            <span className="carousel-control-next-icon" aria-hidden="true"></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </Lbluebg>
    </div>
  );
};

export default Messageslider;
