import React from "react";

function Parallex(props) {
  return (
    <div style={{ backgroundColor: props.bgcolor }} className="py-5 p-5 ">
      <div className="container">
        <div className=" row">
          <div className={`col-md-4 ${props.flip ? "order-2" : ""} `}>
            <div style={{ top: 100 }} className="position-sticky">
              {props.right}
            </div>
          </div>
          <div className="col">{props.left}</div>
        </div>
      </div>
    </div>
  );
}

export default Parallex;
