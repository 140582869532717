import React, { Component } from "react";
import { Blue, Lol, Roundedsmallimg } from "../../../style";

export class Messagesliderindsm extends Component {
  render() {
    const data = this.props.data;
    return (
      data &&
      data.map((x, y) => {
        return (
          <div key={y} className={`carousel-item ${y == 0 ? "active" : ""} `}>
            <div className="row m-5 justify-content-center">
              <div
                style={{ width: 300, margin: "auto" }}
                className="col-md-5 col-lg-4 "
              >
                {" "}
                <div
                  style={{ width: 300, margin: "auto" }}
                  className="col rounded"
                >
                  <div
                    style={{ borderRadius: "30px" }}
                    className="container bg-light p-5"
                  >
                    <Blue className="fs-2 d-block">{x.username}</Blue>
                    <Blue>{x.message}</Blue>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })
    );
  }
}
export class Messagesliderindlg extends Component {
  render() {
    const array = this.props.array;
    const data = this.props.data;

    return (
      array &&
      array.map((x, y) => {
        return (
          <div className={`carousel-item ${y == 0 ? "active" : ""}`}>
            <div className="row p-5 justify-content-center g-5">
              {x.map((y, z) => {
                return (
                  <div className="col-md-5 col-lg-4 ">
                    {" "}
                    <div className="col rounded">
                      <div
                        style={{ borderRadius: "30px" }}
                        className="container bg-light p-5"
                      >
                        <Blue className="fs-2 d-block">{data[y].username}</Blue>
                        <Blue>{data[y].message}</Blue>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })
    );
  }
}
