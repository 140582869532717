import { LockOutlined } from "@ant-design/icons";
import { Button, Input, message } from "antd";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useId from "../../../../useId";
import useToken from "../../../../useToken";

const regularExpression =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
export default function Changepwd({ spanish }) {
  const { Id } = useId();
  const { token } = useToken();
  const [u64, setu64] = useState();
  const [bid, setbid] = useState();
  const [oldpwd, setoldpwd] = useState("");
  const [mainpwd, setmainpwd] = useState("");
  const [main2pwd, setmain2pwd] = useState("");
  const searchParams = useLocation();
  const [firstrender, setfirstrender] = useState(true);
  const [oldempty, oldsetempty] = useState();
  const [empty, setempty] = useState();
  const [errorstatus, seterrorstatus] = useState();
  const [matched, setmatched] = useState();
  const [error, seterror] = useState({
    required: spanish
      ? "Por favor, introduzca su contraseña"
      : "Please enter Your password",
    error: spanish
      ? "Las contraseñas deben tener al menos 8 caracteres, 1 minúscula, 1 mayúscula, 1 número y 1 carácter especial."
      : "Passwords must have at least 8 characters, 1 lowercase, 1 uppercase, 1 number, and 1 special character.",
    match: spanish ? "Las contraseñas no coinciden" : "Passwords don't match",
  });
  const Navigate = useNavigate();

  function postdata() {
    const axios = require("axios");
    let data = JSON.stringify({
      old_password: `${oldpwd}`,
      password: `${mainpwd}`,
      repeat_password: `${main2pwd}`,
    });
    let config = {
      method: "post",
      url:
        process.env.REACT_APP_EXTERNAl_APPURL +
        "v1/users/" +
        Id.username +
        "/change-password/",

      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token" + " " + `${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        message.success(
          spanish
            ? "Contraseña cambiada con éxito"
            : "Password Sucessfully Changed"
        );
        Navigate("/");
      })
      .catch((error) => {
        console.log(error);
        error.response.data.old_password === undefined
          ? message.error(error.message)
          : message.error(error.response.data.old_password);
      });
  }
  return (
    <div>
      <div className="container-md p-0">
        <div>
          {" "}
          <div className="tgrey p-2 fs-6 text-center">
            {spanish
              ? "Continuar con el cambio de contraseña"
              : "Continue to change password"}
          </div>
        </div>
        <div className="container-sm mt-4">
          <div className=""></div>
          <Input.Password
            className="m-auto tgrey "
            size="large"
            placeholder={spanish ? "Contraseña antigua" : "Old Password"}
            prefix={<LockOutlined />}
            status={firstrender ? "" : !oldempty ? "error" : ""}
            onChange={(e) => {
              e.target.value === "" ? oldsetempty(false) : oldsetempty(true);

              setoldpwd(e.target.value);
            }}
          />
          <div className="text-danger p-2 mb-1    pb-0 px-0">
            {" "}
            {oldempty || firstrender ? <></> : error.required}
          </div>
          <Input.Password
            className="m-auto tgrey mt-1 "
            size="large"
            placeholder={spanish ? "Nueva contraseña" : "New Password"}
            prefix={<LockOutlined />}
            status={firstrender ? "" : !empty || !errorstatus ? "error" : ""}
            onChange={(e) => {
              {
                e.target.value === "" ? setempty(false) : setempty(true);
              }
              {
                e.target.value === main2pwd
                  ? setmatched(true)
                  : setmatched(false);
              }

              {
                regularExpression.test(e.target.value)
                  ? seterrorstatus(true)
                  : seterrorstatus(false);
              }
              setmainpwd(e.target.value);
            }}
          />
          <div className="text-danger p-2 m-0 pb-0 px-0">
            {" "}
            {empty || firstrender ? <></> : error.required}
          </div>
          <div className="text-danger p-2 m-0 pt-0 pb-1 px-0">
            {errorstatus || !empty || firstrender ? <></> : error.error}
          </div>
          <div></div>
          <Input.Password
            className="tgrey mt-1"
            size="large"
            placeholder={spanish ? "Confirmar contraseña" : "Confirm Password"}
            prefix={<LockOutlined />}
            status={
              firstrender
                ? ""
                : !errorstatus || !empty || !matched
                ? "error"
                : ""
            }
            onChange={(e) => {
              {
                e.target.value === mainpwd
                  ? setmatched(true)
                  : setmatched(false);
              }
              setmain2pwd(e.target.value);
            }}
          />{" "}
          <div className="text-danger p-2 pb-0 px-0">
            {matched || !empty || firstrender ? <></> : error.match}
          </div>
          <div>
            {" "}
            <Button
              block
              style={{ height: "45px" }}
              className="mt-4"
              size="large"
              type="primary"
              onClick={() => {
                setfirstrender(false);
                {
                  regularExpression.test(mainpwd) & matched ? (
                    postdata()
                  ) : (
                    <></>
                  );
                }
              }}
            >
              {spanish ? "Cambiar la contraseña" : "Change Password"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
