import React, { useEffect, useState } from "react";
import { Button, message } from "antd";
import { Input } from "antd";
import useId from "../../../../useId";
import useToken from "../../../../useToken";
import { useNavigate } from "react-router-dom";
export default function Basic({ spanish }) {
  const { token, setToken } = useToken();
  const navigate = useNavigate();
  const [email, setemail] = useState(sessionStorage.getItem("accountemail"));
  const [uername, setuername] = useState(
    sessionStorage.getItem("accountusername")
  );

  const { Id, setId } = useId();
  const changename = () => {
    const axios = require("axios");
    let data = JSON.stringify({
      first_name: uername,
    });

    let config = {
      method: "patch",
      url: process.env.REACT_APP_EXTERNAl_APPURL + "v1/users/" + Id.username + "/",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: "Token" + " " + `${token}`,
      },
      data: data,
    };

    axios(config)
      .then((response) => {
        message.success("Username updated");
        navigate("/");
      })
      .catch((error) => {
        console.log(error);
        message.error(error.message);
      });
  };

  return (
    <div className=" p-4">
      <h2 className="fs-5 pb-3">
        {" "}
        {spanish ? "Ajustes básicos" : "Basic Settings"}
      </h2>
      <div>
        <div className="row">
          <div className="col">
            <div className="py-2">
              {spanish ? "Correo electrónico" : "Email"}
            </div>
            <div>
              <Input style={{ height: 35 }} disabled value={email}></Input>
            </div>
            <div>
              <div className="pt-4 pb-2">
                {spanish ? "Nombre de la cuenta" : "Account Name"}
              </div>
              <div>
                <Input
                  style={{ height: 35 }}
                  onChange={(e) => {
                    setuername(e.target.value);
                  }}
                  value={uername}
                ></Input>
              </div>
            </div>
            <Button
              onClick={() => {
                changename();
              }}
              className="my-4"
              type="primary"
            >
              {spanish ? "Actualizar la información" : "Update Information"}
            </Button>
          </div>
          <div className="col-8 px-5 py-3">
            <div className="mb  ">Avatar </div>
            <div>
              <img
                height={120}
                src="https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png"
                alt=""
              />
            </div>
            <Button disabled className="my-4" type="primary">
              {spanish ? "Actualizar el avatar" : "Update Avatar"}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
