import React from "react";
import { useState } from "react";
export default function Slider_fixed({ terms }) {
  const [silder, setsilder] = useState(true);
  const [read, set] = terms;
  return (
    <div className="switch">
      {read === "monthly" ? (
        <div className="button" id="button-container">
          <div id="my-button" className="button-element">
            <span id="Monthly">Monthly</span>
          </div>
          <span
            onClick={() => {
              setsilder(false);
              set("yearly");
            }}
            id="Yearly"
          >
            Yearly
          </span>
        </div>
      ) : (
        <div className="button" id="button-container">
          <span
            onClick={() => {
              setsilder(true);
              set("monthly");
            }}
            style={{ paddingRight: 12 }}
            className="ps-0"
            id="Yearly"
          >
            Monthly
          </span>{" "}
          <div id="my-button" className="button-element">
            <span id="Monthly">Yearly</span>
          </div>
        </div>
      )}

      <label hidden>
        <input
          id="for-button"
          type="text"
          name="method"
          value="Yearly"
          readOnly
        />
      </label>
    </div>
  );
}
