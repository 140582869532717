import React from "react";

function Getpricebyplan(prices, plan) {
  if (plan === "Urgent Care") return prices.urgent_care_price;
  if (plan === "Behavioral Health") return prices.behavioral_health_price;
  if (plan === "UC Bundle") return prices.uc_bundle_price;
  if (plan === "Medical Bundle") return prices.medical_bundle_price;
  if (plan === "Total Telehealth") return prices.total_telehealth;
  return null;
}

export default Getpricebyplan;
