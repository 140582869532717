import React from "react";

function Joinus() {
  return (
    <div className="container-fluid ">
      <div className="bg1  row   ">
        <div
          style={{ verticalAlign: "middle" }}
          className=" col text-white fs-2 fw-bold vertical-align-center py-3"
        >
          Book the best price telhealth appointment today.
          <div>
            <div className="lead">
              Find doctors available today. Pay less than anywhere else.
            </div>
            <div className="fs-1 text-center">
              <button
                onClick={() => {
                  window.location.replace("/packages");
                }}
                className="btn homebtn lead px-5 fs-6 py-3 m-3 mb-2    rounded-5"
              >
                Book An Appointment
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Joinus;
